import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale, withSuspense } from "hocs";
import Footer from "layouts/Footer";
import { importScripts, getPagination, splitString } from "utils";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { commonGetCustomerReviews } from "redux/actions";

const { REACT_APP_ENPOINT } = process.env;

@withLocale
@withTranslation("beauty_program.selfie")
@withConnect((state) => ({}))
@withSuspense
class App extends React.Component {
  static getStaticProps = async () => {
    let { content } = await commonGetCustomerReviews()();
    let data = splitString(content, "categories", ";");
    return { data };
  };
  constructor(props) {
    super(props);
    const categories = [
      "Eye",
      "Nose",
      "Face",
      "Breast",
      "Anti-aging",
      "Body",
      "Petit",
      "Others",
    ];
    const pagination = { size: 8 };
    this.pagination = getPagination(props.data, {
      hierarchy: [
        {
          key: "categories",
          values: categories,
        },
      ],
      pagination,
    });
    this.state = this.pagination.$pagination();
    this.prev = (e) => {
      e.preventDefault();
      this.setState(() => this.state.prev());
    };
    this.next = (e) => {
      e.preventDefault();
      this.setState(() => this.state.next());
    };
    this.page = (indexPage) => (e) => {
      e.preventDefault();
      this.setState(() => this.state.page(indexPage));
    };
    this.changePagination = (category) => (e) => {
      e.preventDefault();
      if (this.pagination.tree[0] === category) return;
      this.pagination = getPagination(props.data, {
        hierarchy: [
          {
            key: "categories",
            values: categories,
          },
        ],
        pagination,
      }).$where(category);
      this.setState(() => this.pagination.$pagination());
    };
    this.selectClassName = (category) => {
      return this.pagination.tree[0] === category
        ? "brd_tab_item brd_tab_item_act"
        : "brd_tab_item";
    };
  }
  async componentDidMount() {
    importScripts(this);
  }
  render() {
    const { locale, t } = this.props;
    const { array, currentPage, totalPage } = this.state;
    return (
      <div className="sub beauty">
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          `
          }
          `}
        </style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/beauty_program/remote" locale={locale}>
                  {t("Beauty Program")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link
                      href="/about_us/overview"
                      locale={locale}
                      className="nav_link"
                    >
                      {t("About us")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partners")}
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/beauty_program/selfie" locale={locale}>
                  {t("Real Selfie Review")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/beauty_program/remote" locale={locale}>
                      {t("Remote Consultation")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/recommend" locale={locale}>
                      {t("Recommend Doctors")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/selfie" locale={locale}>
                      {t("Real Selfie Review")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual">
          <div className="sub_txt wrapper relative">
            <h2>{t("Beauty Program")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "Talk with the best Korean specialists and <br />receive medical consultation anywhere with O'young application"
                )
              )}
            </p>
          </div>
        </div>

        <div className="page">
          <div className="sub_cont py-2 wrapper" id="review">
            <div className="first relative">
              <div className="text-center">
                <h2 className="sub_title">{t("Real Selfie Review")}</h2>
              </div>
            </div>

            <div className="sub_in">
              <div className="brd_tab">
                <ul className="brd_tab_row brd_tab_row-9">
                  <li className={this.selectClassName()}>
                    <a href="" onClick={this.changePagination()}>
                      {t("All")}
                    </a>
                  </li>
                  <li className={this.selectClassName("Eye")}>
                    <a href="" onClick={this.changePagination("Eye")}>
                      {t("Eye")}
                    </a>
                  </li>
                  <li className={this.selectClassName("Nose")}>
                    <a href="" onClick={this.changePagination("Nose")}>
                      {t("Nose")}
                    </a>
                  </li>
                  <li className={this.selectClassName("Face")}>
                    <a href="" onClick={this.changePagination("Face")}>
                      {t("Face")}
                    </a>
                  </li>
                  <li className={this.selectClassName("Breast")}>
                    <a href="" onClick={this.changePagination("Breast")}>
                      {t("Breast")}
                    </a>
                  </li>
                  <li className={this.selectClassName("Anti-aging")}>
                    <a href="" onClick={this.changePagination("Anti-aging")}>
                      {t("Anti-aging")}
                    </a>
                  </li>
                  <li className={this.selectClassName("Body")}>
                    <a href="" onClick={this.changePagination("Body")}>
                      {t("Body")}
                    </a>
                  </li>
                  <li className={this.selectClassName("Petit")}>
                    <a href="" onClick={this.changePagination("Petit")}>
                      {t("Petit")}
                    </a>
                  </li>
                  <li className={this.selectClassName("Others")}>
                    <a href="" onClick={this.changePagination("Others")}>
                      {t("Others")}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="brd_gall">
                {array.map((value, key) => (
                  <div className="brd_gall_box" key={key}>
                    <Link
                      href={`/beauty_program/review_view/${value.id}`}
                      locale={locale}
                      className="brd_gall_box_in"
                    >
                      <div className="brd_gall_thumb_wr">
                        <div className="brd_gall_thumb">
                          <img
                            src={
                              value.photos &&
                              value.photos[0] &&
                              `${REACT_APP_ENPOINT}/upload/${value.photos[0].thumbnail}`
                            }
                            alt="thumbnail img"
                            className="img_fixed"
                            style={{
                              width: 305,
                              height: 374,
                            }}
                          />
                        </div>
                        <div className="brd_gall_cate">
                          {value.categories.map((v, key) => (
                            <div className="brd_gall_cate_box" key={key}>
                              {t(v)}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="brd_gall_cont_wr">
                        <p className="brd_gall_tit">{value.subject}</p>
                        <div className="brd_gall_info_wr">
                          <p className="brd_gall_info brd_gall_info_name">
                            {value.customerName}
                          </p>
                          <p className="brd_gall_info brd_gall_info_date">
                            {moment(value.createdAt).format("YYYY-MM-DD")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
              <div className="brd_paging">
                <div className="brd_btn brd_arr brd_prev">
                  <a href="" className="brd_prev_btn" onClick={this.prev}></a>
                </div>
                {new Array(totalPage).fill().map((_, key) => (
                  <div className="brd_btn brd_num" key={key}>
                    <a
                      href=""
                      className={`brd_num_btn ${
                        currentPage === key + 1 ? "brd_num_btn_active" : ""
                      }`}
                      onClick={this.page(key + 1)}
                    >
                      {t(key + 1)}
                    </a>
                  </div>
                ))}
                <div className="brd_btn brd_arr brd_next">
                  <a href="" className="brd_next_btn" onClick={this.next}></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;

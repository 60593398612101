import React from "react";
import { ConfigProvider } from "antd";
import viVN from "antd/lib/locale/vi_VN";
import Header from "./Header";

const Layout = (props) => {
  const { children } = props;
  return (
    <ConfigProvider locale={viVN}>
      <Header />
      {children}
    </ConfigProvider>
  );
};

export default Layout;

import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale, withSuspense } from "hocs";
import Footer from "layouts/Footer";
import { importScripts, getPagination } from "utils";
import { Item } from "components";
import { Form } from "antd";
import { withTranslation } from "react-i18next";
import ellipsize from "ellipsize";
import ReactHtmlParser from "react-html-parser";
import { commonGetProducts } from "redux/actions";

const { REACT_APP_ENPOINT } = process.env;

@withLocale
@withTranslation("products.products")
@withConnect((state) => ({}))
@withSuspense
class App extends React.Component {
  static getStaticProps = async () => {
    let data = await commonGetProducts({ category: "SHOP_BEAUTY" })();

    data = data.map((value) => ({
      ...value,
      i18nProductName: value.i18nProductName
        ? JSON.parse(value.i18nProductName)
        : null,
      i18nShortProductName: value.i18nShortProductName
        ? JSON.parse(value.i18nShortProductName)
        : null,
      i18nDescription: value.i18nDescription
        ? JSON.parse(value.i18nDescription)
        : null,
      functions: value.details.functions,
    }));

    return { data };
  };
  constructor(props) {
    super(props);
    const functions = [
      "HairLoss",
      "Acne",
      "Brightening",
      "Anti-aging",
      "Personal",
      "Professional",
    ];
    const pagination = { size: 9 };

    this.$pagination = getPagination(props.data, {
      hierarchy: [
        {
          key: "functions",
          values: functions,
          fnReturn: (v) => v.functions?.en,
        },
      ],
      pagination,
    });

    this.$filter = this.$pagination.$filter((_) => true);
    this.functions = this.$filter.$where();
    this.state = this.functions.$pagination();

    this.prev = (e) => {
      e.preventDefault();
      this.setState(() => this.state.prev());
    };
    this.next = (e) => {
      e.preventDefault();
      this.setState(() => this.state.next());
    };
    this.page = (indexPage) => (e) => {
      e.preventDefault();
      this.setState(() => this.state.page(indexPage));
    };
    this.changePagination = (value) => (e) => {
      e.preventDefault();
      let { tree } = this.functions;
      if (value === tree[0]) return;
      this.functions = this.$filter.$where(value);
      this.setState(() => this.functions.$pagination());
    };
    this.search = (e) => {
      if (e) e.preventDefault();
      const {
        form: { validateFields },
        locale,
      } = this.props;
      validateFields((errors, values) => {
        if (errors) return;
        let { tree } = this.functions;
        let keyword = values[`keyword`]?.toLowerCase();
        this.$filter = this.$pagination.$filter(
          (v) =>
            v.i18nProductName &&
            v.i18nProductName[locale].toLowerCase().includes(keyword)
        );
        this.functions = this.$filter.$where(tree[0]);
        this.setState(() => this.functions.$pagination());
      });
    };
  }
  async componentDidMount() {
    importScripts(this);
  }
  render() {
    const {
      locale,
      form: { getFieldDecorator },
      t,
    } = this.props;
    const { array, currentPage, totalPage } = this.state;
    return (
      <div className="sub product">
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          #product .page_title .row .col .dep_btn {width: calc(100%/8);}
          `
          }
          `}
        </style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/products/products" locale={locale}>
                  {t("Products")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link
                      href="/about_us/overview"
                      locale={locale}
                      className="nav_link"
                    >
                      {t("About us")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/remote" locale={locale}>
                      {t("Beauty Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partners")}
                    </Link>
                  </li>
                </ul> */}
              </li>

              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/products/products" locale={locale}>
                  {t("O'YOUNG Brand")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("O'YOUNG Brand")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual sub_v2">
          <div className="sub_txt wrapper relative">
            <h2>{t("Products")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "The most effective Stem cell and NK cell origin functional cosmetic <span>for whitening, anti-aging, acne care, scar healing, and hair loss solution.</span>"
                )
              )}
            </p>
          </div>
        </div>

        <div className="page">
          <div className="sub_cont pt-2 pb-4 wrapper" id="product">
            <div className="first relative">
              <div className="text-center">
                <h2 className="sub_title">{t("O'YOUNG Brand")}</h2>
              </div>
            </div>
            <div className="mid relative">
              {/* <ul className="text-center tabs relative">
                <li className="p1">
                  <a href="#tab1" className="tab p1">
                    {t("O'Special Care")}
                  </a>
                </li>
                <li className="p1">
                  <a href="#tab2" className="tab p1">
                    {t("O'YOUNG Brand")}
                  </a>
                </li>
              </ul> */}

              <div className="page tab_container">
                <div id="tab1" className="tab_content">
                  <div className="page_title text-center">
                    {/* <h2 className="mb-1">{t("O'YOUNG Brand")}</h2> */}
                    <div className="row category">
                      <div className="col">
                        <a
                          className="col-7 dep_btn active"
                          onClick={this.changePagination()}
                        >
                          {t("All")}
                        </a>
                        <a
                          className="col-7 dep_btn"
                          onClick={this.changePagination("HairLoss")}
                        >
                          {t("Hair Loss")}
                        </a>
                        <a
                          className="col-7 dep_btn"
                          onClick={this.changePagination("Acne")}
                        >
                          {t("Acne")}
                        </a>
                        <a
                          className="col-7 dep_btn"
                          onClick={this.changePagination("Brightening")}
                        >
                          {t("Brightening")}
                        </a>
                        <a
                          className="col-7 dep_btn"
                          onClick={this.changePagination("Anti-aging")}
                        >
                          {t("Anti-aging")}
                        </a>
                        <a
                          className="col-7 dep_btn"
                          onClick={this.changePagination("Personal")}
                        >
                          {t("Personal")}
                        </a>
                        <a
                          className="col-7 dep_btn"
                          onClick={this.changePagination("Professional")}
                        >
                          {t("Professional")}
                        </a>
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-1 sm_category">
                        {/* <a
                          className="fl-left mr-2 active"
                          href="javascript:void(0)"
                        >
                          {t("All")}
                        </a>
                        <a className="fl-left mr-2" href="javascript:void(0)">
                          {t("New")}
                        </a>
                        <a className="fl-left mr-2" href="javascript:void(0)">
                          {t("Best")}
                        </a>
                        <a className="fl-left mr-2" href="javascript:void(0)">
                          {t("On-sale")}
                        </a> */}
                      </div>
                      <div className="col-4 ">
                        <Form
                          className="prd_search"
                          onSubmit={(e) => this.search(e)}
                          style={{ position: "relative" }}
                        >
                          <Item>
                            {getFieldDecorator(
                              "keyword",
                              {}
                            )(<input type="text" />)}
                            <button type="submit">
                              <img src="/img/sub/sub3_ic.png" alt="" />
                            </button>
                          </Item>
                        </Form>
                      </div>
                    </div>

                    <div className="loop_cont prd_tiles" data-aos="fade-up">
                      {array.map((value, key) => (
                        <Link
                          href={`/products/products_view/${value.id}`}
                          locale={locale}
                          key={key}
                          className="col-3 relative"
                        >
                          <img
                            src={
                              value.banners &&
                              value.banners[0] &&
                              `${REACT_APP_ENPOINT}/upload/${value.banners[0].thumbnail}`
                            }
                            alt=""
                            className="img_fixed"
                            style={{
                              width: 401,
                              height: 499,
                              objectFit: "none"
                            }}
                          />
                          <div className="prd_name absolute text-center">
                            {/* <sub>
                              {value.functions && value.functions[locale]}
                            </sub> */}
                            <h4>
                              {value.i18nProductName &&
                                ellipsize(value.i18nProductName[locale], 33, {
                                  chars: [" ", ","],
                                  truncate: true,
                                })}
                            </h4>
                          </div>
                          <div className="prd_label absolute text-center">
                            {/* {value.functions.en && (
                              <div className="best" key={key}>
                                {t(value.functions.en)}
                              </div>
                            )} */}
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className="brd_paging">
                    <div className="brd_btn brd_arr brd_prev">
                      <a
                        href=""
                        className="brd_prev_btn"
                        onClick={this.prev}
                      ></a>
                    </div>
                    {new Array(totalPage).fill().map((_, key) => (
                      <div className="brd_btn brd_num" key={key}>
                        <a
                          href=""
                          className={`brd_num_btn ${
                            currentPage === key + 1 ? "brd_num_btn_active" : ""
                          }`}
                          onClick={this.page(key + 1)}
                        >
                          {t(key + 1)}
                        </a>
                      </div>
                    ))}
                    <div className="brd_btn brd_arr brd_next">
                      <a
                        href=""
                        className="brd_next_btn"
                        onClick={this.next}
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Form.create()(App);

import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale, withSuspense } from "hocs";
import Footer from "layouts/Footer";
import { importScripts, splitString } from "utils";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import {
  commonGetConsultantById,
  commonGetCustomerReviews,
} from "redux/actions";

const { REACT_APP_ENPOINT } = process.env;

@withLocale
@withTranslation("beauty_program.recommend.recommend_view")
@withConnect((state) => ({}))
@withSuspense
class App extends React.Component {
  static getStaticProps = async ({ uid }) => {
    let data = await commonGetConsultantById(uid)();
    let { content } = await commonGetCustomerReviews({
      consultantId: uid,
    })();

    data.categories = data.categories.split(";").filter((_) => _);
    data.specialties = data.specialties.split(";").filter((_) => _);
    data.i18nProfile = data.i18nProfile ? JSON.parse(data.i18nProfile) : null;
    data.i18nNews = data.i18nNews ? JSON.parse(data.i18nNews) : null;

    data.reviews = splitString(content, "categories", ";");
    return { data };
  };
  async componentDidMount() {
    importScripts(this);
  }
  render() {
    const { locale, t, data } = this.props;
    return (
      <div className="sub beauty">
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          `
          }
          `}
        </style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/beauty_program/remote" locale={locale}>
                  {t("Beauty Program")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link
                      href="/about_us/overview"
                      locale={locale}
                      className="nav_link"
                    >
                      {t("About us")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partners")}
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/beauty_program/recommend" locale={locale}>
                  {t("Recommend Doctors")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/beauty_program/remote" locale={locale}>
                      {t("Remote Consultation")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/recommend" locale={locale}>
                      {t("Recommend Doctors")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/selfie" locale={locale}>
                      {t("Real Selfie Review")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual">
          <div className="sub_txt wrapper relative">
            <h2>{t("Beauty Program")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "Talk with the best Korean specialists and <br />receive medical consultation anywhere with O'young application"
                )
              )}
            </p>
          </div>
        </div>

        <div className="page">
          <div className="sub_cont wrapper" id="review_view">
            <div className="sub_in">
              <div className="brd_view" data-aos="fade-up">
                <div className="row brd_view_doc mb-3">
                  <div className="col-3 brd_view_doc_img">
                    <img
                      src={`${REACT_APP_ENPOINT}/upload/${data.poster.path}`}
                      alt=""
                      className="img_fixed"
                      style={{
                        width: 414,
                        height: 515,
                      }}
                    />
                  </div>
                  <div className="brd_view_doc_info">
                    <div className="brd_view_doc_info_wr pl-4">
                      <div className="brd_view_doc_cate">
                        {data.categories.map((value, key) => (
                          <p className="brd_view_category" key={key}>
                            {t(value)}
                          </p>
                        ))}
                      </div>
                      <h2 className="inline-block brd_view_doc_name">
                        {locale === "ko" ? data.userName : data.englishName}
                      </h2>
                      <sub>{data.field}</sub>
                      <div
                        className="dot"
                        dangerouslySetInnerHTML={{
                          __html: data.i18nProfile && data.i18nProfile[locale],
                        }}
                      ></div>
                      <p className="brd_other_list_tit">{t("Specialties")}</p>
                      <p className="brd_other_list_cont py-1">
                        {data.specialties.join(", ")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="brd_doc_intro">
                  <div className="brd_doc_intro_header py-1">
                    <p className="brd_other_list_tit inline-block">
                      {t("Introduction")}
                    </p>
                    <i className="arrow up fl-right"></i>
                  </div>
                  <div className="brd_doc_intro_cont"></div>
                </div>

                <div className="brd_other_list_wr">
                  <p className="brd_other_list_tit">{t("Other Review")}</p>
                  <div className="brd_doc_other_list">
                    {data.reviews.map((value, key) => (
                      <div className="brd_other_row" key={key}>
                        <div className="brd_other_thumb">
                          <img
                            src={
                              value.photos &&
                              value.photos[0] &&
                              `${REACT_APP_ENPOINT}/upload/${value.photos[0].thumbnail}`
                            }
                            alt=""
                          />
                        </div>
                        <div className="brd_other_txt_wr">
                          {value.categories.map((v, key) => (
                            <p className="brd_other_cate" key={key}>
                              {t(v)}
                            </p>
                          ))}
                          <p className="brd_other_cont">{value.subject}</p>
                        </div>
                        <div className="brd_other_info_wr">
                          <div className="brd_other_info_box">
                            <Link
                              href={`/beauty_program/review_view/${value.id}`}
                              locale={locale}
                              className="brd_other_info brd_other_info_name"
                            >
                              {value.customerName}
                            </Link>
                          </div>
                          <div className="brd_other_info_box">
                            <Link
                              href={`/beauty_program/review_view/${value.id}`}
                              locale={locale}
                              className="brd_other_info brd_other_info_date"
                            >
                              {moment(value.createdAt).format("YYYY-MM-DD")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="brd_doc_ctrl_wr text-center mt-3">
                  <Link
                    href={`/beauty_program/doctor_review/${data.id}`}
                    locale={locale}
                    className="brd_ctrl_list"
                  >
                    {t("More Review")}
                  </Link>
                </div>
                <div className="brd_other_list_wr" data-aos="fade-up">
                  <p className="brd_other_list_tit">{t("Related News")}</p>
                  <div className="brd_doc_other_list">
                    <div className="brd_other_row loop_cont">
                      {/* <div className="col-4 brd_yutb">
                        <div className="brd_yutb_thumb inline-block">
                          <img src="/img/sub/yutb.jpg" alt="" />
                        </div>
                        <div className="brd_yutb_title mb-3">
                          [HUYNDAITV] VIỆN TRƯỞNG AHN KYUNG CHAN - BẬT MÍ NÂNG
                          CƠ ...
                        </div>
                      </div> */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.i18nNews && data.i18nNews[locale],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;

import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale } from "hocs";
import Footer from "layouts/Footer";
import { importScripts } from "utils";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

@withLocale
@withTranslation("about_us.ceo")
@withConnect((state) => ({}))
class App extends React.Component {
  async componentDidMount() {
    importScripts(this);
  }
  render() {
    const { locale, t } = this.props;
    return (
      <div className="sub about">
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          `
          }
          `}
        </style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/about_us/overview" locale={locale}>
                  {t("About us")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program" locale={locale}>
                      {t("Beauty Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partners")}
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/about_us/ceo" locale={locale}>
                  {t("CEO")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/about_us/overview" locale={locale}>
                      {t("Overview")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/about_us/vision" locale={locale}>
                      {t("Vision")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/about_us/business" locale={locale}>
                      {t("Our Business")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/about_us/ceo" locale={locale}>
                      {t("CEO")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual">
          <div className="sub_txt wrapper relative">
            <h2>{t("About us")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "As a leading leader in beauty and health platforms based on trust and value, <span>I want to be a healthy and beautiful partner for customers.</span>"
                )
              )}
            </p>
          </div>
        </div>

        <div className="page">
          <div className="sub_cont py-2 wrapper" id="business">
            <div className="first relative">
              <div className="text-center">
                <h2 className="sub_title">{t("CEO")}</h2>
              </div>
            </div>
            <div className="mid ceo relative text" id="">
              <div className="row" data-aos="fade-up">
                <div class="ceo_pic col-3 fl-right">
                  <img src="/img/sub/sub1_ceo.png" alt="" />
                </div>
                <div className="ceo_txt col-2">
                  <h2 className="ceo_title fl-none">
                    {t("OYOUNG is for family and friends.")}
                  </h2>
                  <p>
                    {t(
                      "We exert ourselves to the fullest to best serve every single moment of our customers’ care journey because OYOUNG is for family and friends."
                    )}
                  </p>
                  <p>
                    {t(
                      "Korea is well-recognized as one of the top countries with ahead-of-the trend innovation and competencies in health and beauty treatment, yet it can be confusing for consumers who are searching for the right treatment and competent doctors."
                    )}
                  </p>
                  <p>
                    {t(
                      "I started OYOUNG after witnessing a close friend seeking surgery for her sister but she could not get answers after sending many emails to Korean hospitals and clinics."
                    )}
                  </p>
                  <p>
                    {t(
                      "We have an overload of information online and in social media but we also have no idea if the information is authentic and trusty-worthy."
                    )}
                  </p>
                  <p>
                    {t(
                      "I want to respond to my family and friends who are asking for the best doctors and services in relation to their specific needs for health and beauty treatments in Korea."
                    )}
                  </p>
                  {t("My team and I are fully committed to this mission.")}
                  <p />
                  <span className="ceo_sign pt-2">
                    {t("Founder and CEO of Oyoung Beauty, ")}
                    {/* <h2>{t("Kim Eden")}</h2> */}
                    <img
                      src="/img/ceo-signature.jpg"
                      alt=""
                      className="img_fixed"
                      style={{
                        objectFit: "contain",
                        width: 150,
                        height: 50,
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;

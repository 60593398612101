import React, { useEffect, useState } from "react";

export const withSuspense = (Component) => {
  return React.forwardRef((props, ref) => {
    const {
      isLoaded,
      match: { params },
    } = props;
    const [isDone, setIsDone] = useState(false);
    const [data, setData] = useState({});
    useEffect(() => {
      if (!isLoaded || isDone) return;
      (async () => {
        try {
          let data = await Component.getStaticProps(params);
          setData(data);
          setIsDone(true);
        } catch (error) {}
      })(params);
    }, [isLoaded, isDone, params]);
    if (!isDone) return "";
    return <Component {...props} {...data} ref={ref} />;
  });
};

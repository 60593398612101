import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale, withSuspense } from "hocs";
import Footer from "layouts/Footer";
import { importScripts, getPagination, splitString } from "utils";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import {
  commonGetCustomerReviewsById,
  commonGetCustomerReviews,
} from "redux/actions";

const { REACT_APP_ENPOINT } = process.env;

@withLocale
@withTranslation("beauty_program.review_view")
@withConnect((state) => ({}))
@withSuspense
class App extends React.Component {
  static getStaticProps = async ({ uid }) => {
    let data = await commonGetCustomerReviewsById(uid)();
    let { content } = await commonGetCustomerReviews({
      consultantId: data.consultantId,
    })();

    data.categories = data.categories.split(";").filter((_) => _);
    data.reviews = splitString(content, "categories", ";");

    return { data };
  };
  async componentDidMount() {
    importScripts(this);
  }
  render() {
    const { locale, t, data } = this.props;
    const { array: reviews } = getPagination(data.reviews, {
      filter: [(v) => v.id !== data.id],
      pagination: { size: 8 },
    }).$pagination();
    return (
      <div className="sub beauty">
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          `
          }
          `}
        </style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/beauty_program/remote" locale={locale}>
                  {t("Beauty Program")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link
                      href="/about_us/overview"
                      locale={locale}
                      lassName="nav_link"
                    >
                      {t("About us")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partners")}
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/beauty_program/selfie" locale={locale}>
                  {t("Real Selfie Review")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/beauty_program/remote" locale={locale}>
                      {t("Remote Consultation")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/recommend" locale={locale}>
                      {t("Recommend Doctors")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/selfie" locale={locale}>
                      {t("Real Selfie Review")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual">
          <div className="sub_txt wrapper relative">
            <h2>{t("Beauty Program")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "Talk with the best Korean specialists and <br />receive medical consultation anywhere with O'young application"
                )
              )}
            </p>
          </div>
        </div>

        <div className="page">
          <div className="sub_cont wrapper" id="review_view">
            <div className="sub_in">
              <div className="brd_view">
                <div className="brd_view_header">
                  <div className="brd_view_tit_wr">
                    {data.categories.map((value, key) => (
                      <p className="brd_view_category" key={key}>
                        {t(value)}
                      </p>
                    ))}
                    <h1 className="brd_view_tit">{data.subject}</h1>
                  </div>
                  <div className="brd_view_info_wr">
                    <p className="brd_view_writer">{data.customerName}</p>
                    <p className="brd_view_date">
                      {moment(data.createdAt).format("YYYY-MM-DD")}
                    </p>
                  </div>
                </div>

                <div className="brd_view_cont">
                  <div className="brd_view_thumb">
                    {data.photos.map((value, key) => (
                      <img
                        src={`${REACT_APP_ENPOINT}/upload/${value.path}`}
                        alt=""
                        key={key}
                      />
                    ))}
                  </div>
                  <p className="brd_view_txt">{data.content}</p>
                </div>

                <div className="brd_view_add">
                  <div className="brd_view_add_row">
                    <div className="brd_view_add_item brd_view_add_tit">
                      {t("Surgery Day")}
                    </div>
                    <div className="brd_view_add_item brd_view_add_txt">
                      <p>{data.surgeryYmd}</p>
                    </div>
                  </div>
                  <div className="brd_view_add_row">
                    <div className="brd_view_add_item brd_view_add_tit">
                      {t("Clinic")}
                    </div>
                    <div className="brd_view_add_item brd_view_add_txt">
                      <p>
                        {locale === "ko"
                          ? data.consultant?.partner?.userName
                          : data.consultant?.partner?.englishName}
                      </p>
                    </div>
                  </div>
                  <div className="brd_view_add_row">
                    <div className="brd_view_add_item brd_view_add_tit">
                      {t("Doctor")}
                    </div>
                    <div className="brd_view_add_item brd_view_add_txt">
                      <Link
                        href={`/beauty_program/recommend/${data.consultantId}`}
                        locale={locale}
                      >
                        {locale === "ko"
                          ? data.consultant.userName
                          : data.consultant.englishName}
                        <img src="/img/sub/sub5_ic2.png" alt="" />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="brd_other_list_wr">
                  <p className="brd_other_list_tit">{t("Other Review")}</p>
                  <div className="brd_other_list">
                    {reviews.map((value, key) => (
                      <div className="brd_other_row" key={key}>
                        <div className="brd_other_thumb">
                          <img
                            src={
                              value.photos &&
                              value.photos[0] &&
                              `${REACT_APP_ENPOINT}/upload/${value.photos[0].thumbnail}`
                            }
                            alt=""
                          />
                        </div>
                        <div className="brd_other_txt_wr">
                          {value.categories.map((v, key) => (
                            <p className="brd_other_cate" key={key}>
                              {t(v)}
                            </p>
                          ))}
                          <p className="brd_other_cont">{value.subject}</p>
                        </div>
                        <div className="brd_other_info_wr">
                          <div className="brd_other_info_box">
                            <Link
                              href={`/beauty_program/review_view/${value.id}`}
                              locale={locale}
                              className="brd_other_info brd_other_info_name"
                            >
                              {value.customerName}
                            </Link>
                          </div>
                          <div className="brd_other_info_box">
                            <Link
                              href={`/beauty_program/review_view/${value.id}`}
                              locale={locale}
                              className="brd_other_info brd_other_info_date"
                            >
                              {moment(value.createdAt).format("YYYY-MM-DD")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="brd_ctrl_wr">
                  <Link
                    href={`/beauty_program/doctor_review/${data.consultantId}`}
                    locale={locale}
                    className="brd_ctrl_list"
                  >
                    {t("Menu")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;

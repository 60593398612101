export function callAfter(callAfterFunction) {
  return (target, key, descriptor) => {
    var originalMethod = descriptor.value;
    descriptor.value = async function (...args) {
      let result = await originalMethod.apply(this, args);
      if (typeof callAfterFunction === "string" && target[callAfterFunction]) {
        await target[callAfterFunction].apply(this, args);
      }
      if (typeof callAfterFunction === "function") {
        await callAfterFunction.apply(this, args);
      }
      return result;
    };
    return descriptor;
  };
}

export const setStatePromise = function (state) {
  return new Promise((resolve, reject) => {
    this.setState(state, resolve);
  });
};

import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale, withSuspense } from "hocs";
import Footer from "layouts/Footer";
import { importScripts } from "utils";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import ellipsize from "ellipsize";
import { commonGetRecommendedConsultants } from "redux/actions";

const { REACT_APP_ENPOINT } = process.env;

@withLocale
@withTranslation("beauty_program.recommend")
@withConnect((state) => ({}))
@withSuspense
class App extends React.Component {
  static getStaticProps = async () => {
    let data = await commonGetRecommendedConsultants()();
    data.forEach((value) => {
      value.categories = value.categories.split(";").filter((_) => _);
    });
    return { data };
  };
  async componentDidMount() {
    importScripts(this);
  }
  render() {
    const { locale, t, data } = this.props;
    return (
      <div className="sub beauty">
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          `
          }
          `}
        </style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/beauty_program/remote" locale={locale}>
                  {t("Beauty Program")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link
                      href="/about_us/overview"
                      locale={locale}
                      className="nav_link"
                    >
                      {t("About us")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partners")}
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/beauty_program/recommend" locale={locale}>
                  {t("Recommend Doctors")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/beauty_program/remote" locale={locale}>
                      {t("Remote Consultation")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/recommend" locale={locale}>
                      {t("Recommend Doctors")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/selfie" locale={locale}>
                      {t("Review")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual">
          <div className="sub_txt wrapper relative">
            <h2>{t("Beauty Program")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "Talk with the best Korean specialists and <br />receive medical consultation anywhere with O'young application"
                )
              )}
            </p>
          </div>
        </div>

        <div className="page">
          <div className="sub_cont pt-2 pb-4 wrapper" id="">
            <div className="first relative">
              <div className="text-center">
                <h2 className="sub_title">{t("Recommend Doctors")}</h2>
              </div>
              <div className="title_img relative" data-aos="fade-up">
                <img src="/img/sub/sub5_mid2.jpg" alt="" className="my-1" />
              </div>
            </div>
            <div className="recommend">
              <div className="brd_gall">
                {data.map((value, key) => (
                  <div className="brd_gall_box" key={key}>
                    <Link
                      href={`/beauty_program/recommend/${value.id}`}
                      locale={locale}
                      className="brd_gall_box_in"
                    >
                      <div className="brd_gall_thumb_wr">
                        <div className="brd_gall_thumb">
                          <img
                            src={`${REACT_APP_ENPOINT}/upload/${value.poster.path}`}
                            alt="thumbnail img"
                            className="img_fixed"
                            style={{
                              width: 305,
                              height: 374,
                            }}
                          />
                        </div>
                      </div>
                      <div className="brd_gall_cont_wr">
                        <p className="brd_gall_tit">
                          {locale === "ko"
                            ? value.userName
                            : value.englishName}
                        </p>
                        <sub className="brd_gall_sub">
                          {value.field || (
                            <span style={{ visibility: "hidden" }}>.</span>
                          )}
                        </sub>
                        <div className="brd_gall_info_wr">
                          <p className="brd_gall_info brd_gall_info_job">
                            {ellipsize(value.categories.join(", "), 33, {
                              chars: [" ", ","],
                              truncate: true,
                            })}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;

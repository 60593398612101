import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import i18next from "i18next";
import { withLocale } from "hocs";
import Layout from "layouts";

import Home from "containers/Home";
//AboutUs
import Overview from "containers/AboutUs/Overview";
import Vision from "containers/AboutUs/Vision";
import Business from "containers/AboutUs/Business";
import CEO from "containers/AboutUs/CEO";
//HealthProgram
import Health from "containers/HealthProgram/Health";
import HealthReview from "containers/HealthProgram/HealthReview";
//BeautyProgram
import Remote from "containers/BeatyProgram/Remote";
import Recommend from "containers/BeatyProgram/Recommend";
import RecommendView from "containers/BeatyProgram/Recommend/RecommendView";
import Selfie from "containers/BeatyProgram/Selfie";
import ReviewView from "containers/BeatyProgram/ReviewView"
import DoctorReview from "containers/BeatyProgram/DoctorReview";
//Products
import Products from "containers/Products/Products";
import ProductsView from "containers/Products/ProductsView";
// //Partners
import Partner from "containers/Partners/Partner";

const base = "/:locale(en|vi|ko)";

const Localizer = (props) => {
  const {
    match: {
      params: { locale: nextLocale },
    },
    locale: currentLocale,
    changeLocale,
  } = props;
  useEffect(() => {
    if (currentLocale === nextLocale) return;
    i18next.changeLanguage(nextLocale || "en").then(() => {
      changeLocale(nextLocale);
    });
  }, [currentLocale, nextLocale, changeLocale]);
  return "";
};

const GlobalRouter = (props) => {
  return (
    <Router>
      <Layout>
        <Route path={base} component={withLocale(Localizer)} />
        <Switch>
          <Route exact path={base} component={Home} />

          <Route exact path={`${base}/about_us/overview`} component={Overview} />
          <Route exact path={`${base}/about_us/vision`} component={Vision} />
          <Route exact path={`${base}/about_us/business`} component={Business} />
          <Route exact path={`${base}/about_us/ceo`} component={CEO} />

          <Route exact path={`${base}/health_program/health`} component={Health} />
          <Route exact path={`${base}/health_program/health_review`} component={HealthReview} />

          <Route exact path={`${base}/beauty_program/remote`} component={Remote} />
          <Route exact path={`${base}/beauty_program/recommend`} component={Recommend} />
          <Route exact path={`${base}/beauty_program/recommend/:uid`} component={RecommendView} />
          <Route exact path={`${base}/beauty_program/selfie`} component={Selfie} />
          <Redirect from={`${base}/beauty_program/review_view/:uid`} to={`${base}/beauty_program/review/view/:uid`}/>
          <Route exact path={`${base}/beauty_program/review/view/:uid`} component={ReviewView} />
          <Route exact path={`${base}/beauty_program/doctor_review/:uid`} component={DoctorReview} />

          <Route exact path={`${base}/products/products`} component={Products} />
          <Redirect from={`${base}/products/products_view/:uid`} to={`${base}/products/view/:uid`}/>
          <Route exact path={`${base}/products/view/:uid`} component={ProductsView} />

          <Route exact path={`${base}/partners/partner`} component={Partner} />

          <Redirect to="/en" />
        </Switch>
      </Layout>
    </Router>
  );
};

export default withLocale(GlobalRouter);

import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";

import reducers from "redux/reducers";

export const store = createStore(
  reducers,
  compose(
    applyMiddleware(thunkMiddleware),
    typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : (f) => f
  )
);

import React, { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { Provider } from "react-redux";
import { store } from "redux/store";
import GlobalRouter from "routers";
import { LocaleProvider } from "hocs";
import "moment/locale/vi";
import "antd/es/notification/style/index.css";
import "styles/main.scss";

i18next.use(ChainedBackend).init({
  nsSeparator: false,
  keySeparator: false,
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "span", "b"],
  },
  fallbackLng: "en",
  backend: {
    backends: [LocalStorageBackend, HttpBackend],
    backendOptions: [
      {
        expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
      },
      {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
    ],
  },
});

const App = (props) => {
  return (
    <I18nextProvider i18n={i18next}>
      <Suspense fallback={() => ""}>
        <Provider store={store}>
          <LocaleProvider>
            <GlobalRouter />
          </LocaleProvider>
        </Provider>
      </Suspense>
    </I18nextProvider>
  );
};

export default App;

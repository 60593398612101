import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale } from "hocs";
import { commonContactFormSubmission } from "redux/actions";
import { trimObject, importScripts } from "utils";
import Footer from "layouts/Footer";
import notification from "utils/notification";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import Popup from "./Popup";

@withLocale
@withTranslation("health_program.health_review")
@withConnect((state) => ({}))
class App extends React.Component {
  async componentDidMount() {
    importScripts(this);
  }
  onClick = async (e) => {
    e.preventDefault();
    if (!this.ref) return;
    try {
      const { dispatch } = this.props;
      let values = await this.ref.onSubmit();
      trimObject(values);
      try {
        await dispatch(commonContactFormSubmission(values));
        return notification.success("Success");
      } catch (error) {
        return notification.error("Fail");
      }
    } catch (error) {}
  };
  render() {
    const { locale, t } = this.props;
    return (
      <div className="sub health">
        <style>{`
          #health .qna_btn:hover::after {content: "${t(
            "OCP Consultation Reservation"
          )}"; ${locale === "vi" ? "padding: 0;" : ""}}
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          `
          }
        `}</style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/health_program/health" locale={locale}>
                  {t("Health Program")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link
                      href="/about_us/overview"
                      locale={locale}
                      className="nav_link"
                    >
                      {t("About us")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/remote" locale={locale}>
                      {t("Beauty Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partners")}
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/health_program/health" locale={locale}>
                  {t("Real Experience Review")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("OYOUNG Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/health_program/health_review" locale={locale}>
                      {t("Real Experience Review")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual">
          <div className="sub_txt wrapper relative">
            <h2>{t("Health Program")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "OYOUNG always Starts with with consumer empathy and understanding <span>and moves forward into the future</span>"
                )
              )}
            </p>
          </div>
        </div>
        <div className="page">
          <div className="sub_cont py-2 wrapper" id="health">
            <div className="cont">
              <div className="first relative">
                <div className="text-center">
                  <h2 className="sub_title">{t("REAL EXPERIENCE REVIEW")}</h2>
                </div>
                <div className="title_img relative" data-aos="fade-up">
                  <img src="/img/sub/sub4-2_mid.jpg" alt="" />
                  <div className="wbox absolute text-center">
                    <p>
                      {ReactHtmlParser(
                        t(
                          "Understanding the worries and concerns of people we love is the <span>greatest motivation for us to launch the O'young Health Program.</span>"
                        )
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative">
              <div className="qna_btn center" onClick={this.onClick}>
                <a href="/" className="center p2">
                  <img src="/img/sub/sub4_ic5.png" alt="" />
                </a>
              </div>
              <Popup ref={(ref) => (this.ref = ref)} />
            </div>
            <div className="sect">
              <div className="one_column_list mb-4 border-bottom">
                <div className="row" data-aos="fade-left">
                  <div className="one_column_inner">
                    <div className="row one_column_cont py-2 mb-3">
                      <div className="col-1 one_column_txt pr-4">
                        <h3 className="one_column_title">
                          {t("Kim XX, 65-year-old woman living in Jeju")}
                        </h3>
                        <ul className="para ">
                          <li>
                            {t(
                              "There have been times when my knees hurt and times when they hurt less for a few years, but the pain has gotten worse since May 2020. I went to the hospital because I was very sick and I was diagnosed with degenerative arthritis. I removed water from my left knee twice, but it still looked swollen and the pain lasted throughout the day."
                            )}
                          </li>
                          <li>
                            {t(
                              "At the end of last year, I got to know Ohyoung stem Health Program and she was so kind to consult me, so I thought it was a reliable place, and I finally decided to get treatment. Before the treatment, I had a thorough examination, and while receiving stem cell treatment, I am changing my lifestyle little by little according to the doctor's prescription."
                            )}
                          </li>
                          <li>
                            {t(
                              "I felt the effect after taking the third stem cell injection. The pain on both sides of my knee is almost gone and it's not hard to walk or go up stairs. I have 2 more treatments left, and I'm looking forward to it because I think I'll be able to walk in good health after this is over."
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="col-4 one_column_img">
                        <img src="/img/sub/sub4-2_bot1.jpg" alt="" />
                      </div>
                    </div>
                    <div className="one_column_open text-center my-2">
                      <i className="arrow down"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="one_column_list mb-4 border-bottom">
                <div className="row" data-aos="fade-left">
                  <div className="one_column_inner">
                    <div className="row one_column_cont py-2 mb-3">
                      <div className="col-1 one_column_txt pr-4">
                        <h3 className="one_column_title">
                          {t(
                            "Park XX, 57-year-old man, living in Gyeonggi-do, Korea"
                          )}
                        </h3>
                        <ul className="para ">
                          <li>
                            {t(
                              "I was diagnosed with diabetes four years ago. Since then, I started dieting and take a lot of medicine and supplements. But due to the nature of my job, I often drink alcohol and get stressed out, but I can't even eat properly when I'm too busy. My health has been very weak recently, so I often get tired and can't concentrate on my work. Also, diabetes caused problems in my liver, kidneys, and other parts of my body, and my wife was very worried about my health."
                            )}
                          </li>
                          <li>
                            {t(
                              "I was introduced by an acquaintance, who was consulted by Oh Young Health Program, participated in the program, and was prescribed 10 stem cell IV procedures. It's very comfortable because the doctor and nurse come to my house every time and proceed with the treatment. You said it takes at least three months for the cells in the body to regenerate when stem cells begin, but now you have been treated for the fifth time and you can feel your health is getting better. I sleep well and it's not that hard when I get off work, and it's helping my appetite. The diabetes index is also improving."
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="col-4 one_column_img">
                        <img src="/img/sub/sub4-2_bot2.jpg" alt="" />
                      </div>
                    </div>
                    <div className="one_column_open text-center my-2">
                      <i className="arrow down"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="one_column_list mb-4 border-bottom">
                <div className="row" data-aos="fade-left">
                  <div className="one_column_inner">
                    <div className="row one_column_cont py-2 mb-3">
                      <div className="col-1 one_column_txt pr-4">
                        <h3 className="one_column_title">
                          {t("Kim XX, 49-year-old man living in Seoul")}
                        </h3>
                        <ul className="para ">
                          <li>
                            {t(
                              "I've been losing a lot of hair for the past 6 months, probably because of work stress or poor health care. My hair loss symptoms got worse, so I tried various treatment methods, but it doesn't work. I was worried that my hair would become bald if I kept losing it like this way, I couldn't sleep."
                            )}
                          </li>
                          <li>
                            {t(
                              "That's when I got to know Oh Young Cell through a friend I knew. My brother had the same hair loss symptoms as I did, but he got much better after treatment, and it's effective to see with his eyes. So I immediately contacted Oyoung counselor and got a consultation. It is a treatment process that improves the weakened hair follicle and scalp environment by injecting stem cell concentrate into the scalp and generating healthy cells. After treatment 3 times, new hair is shown to grow. Originally, hair is weak and thin, but new hair is growing strong. I don't know how happy I was to see the effect. I regained my confidence as well as my health and appearance."
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="col-4 one_column_img">
                        <img src="/img/sub/sub4-2_bot3.jpg" alt="" />
                      </div>
                    </div>
                    <div className="one_column_open text-center my-2">
                      <i className="arrow down"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;

import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale, withSuspense } from "hocs";
import { commonContactFormSubmission } from "redux/actions";
import { trimObject, importScripts } from "utils";
import Footer from "layouts/Footer";
import notification from "utils/notification";
import { withTranslation } from "react-i18next";
import ellipsize from "ellipsize";
import ReactHtmlParser from "react-html-parser";
import { commonGetStatistics, commonGetProducts } from "redux/actions";
import Popup from "./Popup";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import chunk from "lodash.chunk";

const filesLink = {
  en: "/files/Privacy Statement in English.pdf",
  vi: "/files/Privacy Statement in Vietnamese.pdf",
  ko: "/files/Privacy Statement in Korean.pdf",
};

const { REACT_APP_ENPOINT } = process.env;

@withLocale
@withTranslation(["home", "footer"])
@withConnect((state) => ({}))
@withSuspense
class App extends React.Component {
  static getStaticProps = async () => {
    let data = await commonGetStatistics()();
    let products = await commonGetProducts({ category: "SHOP_BEAUTY" })();
    let {
      data: { partners },
    } = await axios.get("/json/home.json");
    products = products.map((value) => ({
      ...value,
      i18nProductName: value.i18nProductName
        ? JSON.parse(value.i18nProductName)
        : null,
      i18nShortProductName: value.i18nShortProductName
        ? JSON.parse(value.i18nShortProductName)
        : null,
      i18nDescription: value.i18nDescription
        ? JSON.parse(value.i18nDescription)
        : null,
      functions: value.details.functions,
    }));
    return { data, products, partners };
  };
  async componentDidMount() {
    importScripts(this, true);
    setTimeout(this.animate, 0);
  }
  animate = () => {
    const { $ } = window;
    const self = this;
    let header = $("header");
    let lang_wr = $(".nav_lang");
    let lang = $(".nav_lang .nav_lang_item");
    let active = $(".nav_lang_item.active");

    $("#fullpage").fullpage({
      afterRender: function () {
        // self.interval = setInterval(function () {
        //   if (typeof $.fn.fullpage.moveSlideRight === "function") {
        //     try {
        //       $.fn.fullpage.moveSlideRight();
        //     } catch (error) {}
        //   }
        // }, 3000);
      },
      responsiveWidth: 1580,
      slidesNavigation: true,
      autoScrolling: true,
      navigation: true,
      navigationPosition: "right",
      anchors: ["1", "2", "3", "4"],
      afterLoad: function (anchorLink, index) {
        if (anchorLink === 1) {
          header.css("background", "rgba(255,255,255,0.2)");
          lang.css("color", "#fff");
          lang_wr.css("border-color", "#fff");
          header.hover(
            function () {
              header.css("background", "rgba(255,255,255)");
              lang.css("color", "#a1a1a0");
              lang_wr.css("border-color", "#a1a1a0");
              // active.css("color", "#fff");
            },
            function () {
              header.css("background", "rgba(255,255,255,0.2)");
              lang.css("color", "#fff");
              lang_wr.css("border-color", "#fff");
              // active.css("color", "#fff");
            }
          );
        } else if (anchorLink === 2) {
          header.css("background", "rgba(255,255,255)");
          lang.css("color", "#a1a1a0");
          lang_wr.css("border-color", "#a1a1a0");
          // active.css("color", "#fff");
          header.hover(
            function () {
              header.css("background", "rgba(255,255,255)");
              lang.css("color", "#a1a1a0");
              lang_wr.css("border-color", "#a1a1a0");
              // active.css("color", "#fff");
            },
            function () {
              header.css("background", "rgba(255,255,255)");
              lang.css("color", "#a1a1a0");
              lang_wr.css("border-color", "#a1a1a0");
              // active.css("color", "#fff");
            }
          );
        } else if (anchorLink === 3) {
          header.css("background", "rgba(255,255,255,0.2)");
          lang.css("color", "#a1a1a0");
          lang_wr.css("border-color", "#a1a1a0");
          header.hover(
            function () {
              lang.css("color", "#a1a1a0");
              lang_wr.css("border-color", "#a1a1a0");
            },
            function () {
              lang.css("color", "#a1a1a0");
              lang_wr.css("border-color", "#a1a1a0");
            }
          );
        } else {
          header.css("background", "rgba(255,255,255,0.2)");
          lang.css("color", "#fff");
          lang_wr.css("border-color", "#fff");
          header.hover(
            function () {
              header.css("background", "rgba(255,255,255)");
              lang.css("color", "#a1a1a0");
              lang_wr.css("border-color", "#a1a1a0");
            },
            function () {
              header.css("background", "rgba(255,255,255,0.2)");
              lang.css("color", "#fff");
              lang_wr.css("border-color", "#fff");
            }
          );
        }
      },
    });

    $(".partner_slide").owlCarousel({
      items: 6,
      nav: true,
      navText: [
        '<div class="nav-button owl-prev"><i class="arrow left"></i></div>',
        '<div class="nav-button owl-next"><i class="arrow right"></i></div>',
      ],
      dots: false,
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 5,
        },
        1000: {
          items: 6,
        },
      },
    });
  };
  onClick = async (e) => {
    e.preventDefault();
    if (!this.ref) return;
    try {
      const { dispatch } = this.props;
      let values = await this.ref.onSubmit();
      trimObject(values);
      try {
        await dispatch(commonContactFormSubmission(values));
        return notification.success("Success");
      } catch (error) {
        return notification.error("Fail");
      }
    } catch (error) {}
  };
  componentWillUnmount() {
    clearInterval(this.interval);
    try {
      const { $ } = window;
      $("header").unbind("mouseenter mouseleave");
      $.fn.fullpage.destroy("#fullpage");
    } catch (error) {}
  }
  render() {
    const { locale, t, data, products, partners } = this.props;
    const tFooter = (value) => t(value, { ns: "footer" });
    return (
      <React.Fragment>
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          .main_4_bottom .col-2:first-child {width: 70%;}
          .main_4_bottom .col-2:last-child {width: 30%;}
          .main_3 .wbox_img p {font-size: 13px; line-height: 1;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          .main_4_bottom .col-2:first-child {width: 70%;}
          .main_4_bottom .col-2:last-child {width: 30%;}
          .main_3 .wbox_img p {font-size: 13px; line-height: 1; width: 70%; margin: 15px auto;}
          `
          }
          `}
        </style>
        <div id="fullpage" className="main">
          <div className="main_1 section">
            <div className="slide">
              <div className="row wrapper">
                <div className="col-2 main_1_txt">
                  <h1>
                    {ReactHtmlParser(
                      t("Your trusted companion on your journey to beauty")
                    )}
                  </h1>
                  <p>
                    {/* {ReactHtmlParser(
                      t(
                        "By innovative business models and Korea's outstanding technology, O'YOUNG BEAUTY connects people around the world who are seeking healthcare, beauty enhancement, and plastic surgery services in Korea. 1"
                      )
                    )} */}
                    {ReactHtmlParser(
                      t(
                        "By innovative business models and Korea's outstanding technology, O'YOUNG BEAUTY connects people around the world who are seeking healthcare, beauty enhancement, and plastic surgery services in Korea."
                      )
                    )}
                  </p>
                  <div className="app">
                    {/* <p>{t("App Download")}</p> */}
                    <div className="">
                      <a href="https://play.google.com/store">
                        <img
                          src="/img/googleplay.png"
                          alt="googleplay"
                          className="img_fixed"
                          style={{
                            width: 175,
                            height: 60,
                          }}
                        />
                      </a>
                      <a href="https://www.apple.com/kr/app-store/">
                        <img
                          src="/img/appstore.png"
                          alt="googleplay"
                          className="img_fixed"
                          style={{
                            width: 175,
                            height: 60,
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="row wrapper">
                <div className="col-2 main_1_txt">
                  <h1>
                    {ReactHtmlParser(
                      t("Your trusted companion on your journey to beauty")
                    )}
                  </h1>
                  <p>
                    {ReactHtmlParser(
                      t(
                        "By innovative business models and Korea's outstanding technology, O'YOUNG BEAUTY connects people around the world who are seeking healthcare, beauty enhancement, and plastic surgery services in Korea. 2"
                      )
                    )}
                  </p>
                  <div className="app">
                    {/* <p>{t("App Download")}</p> */}
                    <div className="">
                      <a href="https://play.google.com/store">
                        <img src="/img/googleplay.png" alt="googleplay" />
                      </a>
                      <a href="https://www.apple.com/kr/app-store/">
                        <img src="/img/appstore.png" alt="googleplay" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="row wrapper">
                <div className="col-2 main_1_txt">
                  <h1>
                    {ReactHtmlParser(
                      t("Your trusted companion on your journey to beauty")
                    )}
                  </h1>
                  <p>
                    {ReactHtmlParser(
                      t(
                        "By innovative business models and Korea's outstanding technology, O'YOUNG BEAUTY connects people around the world who are seeking healthcare, beauty enhancement, and plastic surgery services in Korea. 3"
                      )
                    )}
                  </p>
                  <div className="app">
                    {/* <p>{t("App Download")}</p> */}
                    <div className="">
                      <a href="https://play.google.com/store">
                        <img src="/img/googleplay.png" alt="googleplay" />
                      </a>
                      <a href="https://www.apple.com/kr/app-store/">
                        <img src="/img/appstore.png" alt="googleplay" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="row wrapper">
                <div className="col-2 main_1_txt">
                  <h1>
                    {ReactHtmlParser(
                      t("Your trusted companion on your journey to beauty")
                    )}
                  </h1>
                  <p>
                    {ReactHtmlParser(
                      t(
                        "By innovative business models and Korea's outstanding technology, O'YOUNG BEAUTY connects people around the world who are seeking healthcare, beauty enhancement, and plastic surgery services in Korea. 4"
                      )
                    )}
                  </p>
                  <div className="app">
                    {/* <p>{t("App Download")}</p> */}
                    <div className="">
                      <a href="https://play.google.com/store">
                        <img src="/img/googleplay.png" alt="googleplay" />
                      </a>
                      <a href="https://www.apple.com/kr/app-store/">
                        <img src="/img/appstore.png" alt="googleplay" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="pause">
              <img src="/img/stop_ic.png" alt="" />
            </button>
          </div>
          <div className="main_2 section">
            <div className="wrapper">
              <div className="col-2 main_2_left">
                <div className="main_2_txt pb-1">
                  <h2 className="title relative">{t("Products")}</h2>
                  <p>
                    {ReactHtmlParser(
                      t(
                        "We provide Stem cell and NK cell-based cosmeceuticals products as beauty enhancement solutions such as anti-aging, whitening, hair loss, scar healing, etc for our B2B partners."
                      )
                    )}
                  </p>
                </div>
                <div className="main_2_img">
                  <div className="row">
                    {products.slice(0, 2).map((value, key) => (
                      <div className="col-2 text-center" key={key}>
                        <Link
                          href={`/products/products_view/${value.id}`}
                          locale={locale}
                        >
                          <img
                            src={
                              value.banners &&
                              value.banners[0] &&
                              `${REACT_APP_ENPOINT}/upload/${value.banners[0].thumbnail}`
                            }
                            alt=""
                            className="img_fixed"
                            style={{
                              width: 97,
                              height: 146,
                              objectFit: "cover",
                            }}
                          />
                          <h3>
                            {value.i18nProductName &&
                              ellipsize(value.i18nProductName[locale], 33, {
                                chars: [" ", ","],
                                truncate: true,
                              })}
                          </h3>
                        </Link>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    {products.slice(2, 4).map((value, key) => (
                      <div className="col-2 text-center" key={key}>
                        <Link
                          href={`/products/products_view/${value.id}`}
                          locale={locale}
                        >
                          <img
                            src={
                              value.banners &&
                              value.banners[0] &&
                              `${REACT_APP_ENPOINT}/upload/${value.banners[0].thumbnail}`
                            }
                            alt=""
                            className="img_fixed"
                            style={{
                              width: 97,
                              height: 146,
                              objectFit: "cover",
                            }}
                          />
                          <h3>
                            {value.i18nProductName &&
                              ellipsize(value.i18nProductName[locale], 33, {
                                chars: [" ", ","],
                                truncate: true,
                              })}
                          </h3>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main_3 section">
            <div className="wrapper">
              <div className="row">
                <div className="col">
                  <h2 className="title relative">{t("O’ young services")}</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-2 wbox">
                  <div className="wbox_txt">
                    <h3
                      className="wbox_title relative"
                      style={{ marginBottom: "1rem" }}
                    >
                      {t("Health Program")}
                    </h3>
                    <p>
                      {t(
                        "Customers can experience the best health and immunity improvement programs at O'young Beauty."
                      )}
                    </p>
                  </div>
                  <div className="wbox_img">
                    <div className="back_fill">
                      <div class="icon_wr">
                        <img src="/img/i-1.png" alt="" />
                      </div>
                      <p>{t("Safety")}</p>
                    </div>
                    <div className="back_fill">
                      <div class="icon_wr">
                        <img src="/img/i-2.png" alt="" />
                      </div>
                      <p>{t("Effectiveness")}</p>
                    </div>
                    <div className="back_fill">
                      <div class="icon_wr">
                        <img src="/img/i-3.png" alt="" />
                      </div>
                      <p>{t("Convenience")}</p>
                    </div>
                    <div className="back_fill">
                      <div class="icon_wr">
                        <img src="/img/i-4.png" alt="" />
                      </div>
                      <p>{t("Accessibility")}</p>
                    </div>
                  </div>
                  <Link
                    href="/health_program/health"
                    locale={locale}
                    className="more relative"
                  >
                    {t("MORE")}
                  </Link>
                </div>
                <div className="col-2 wbox">
                  <div className="wbox_txt">
                    <h3
                      className="wbox_title relative"
                      style={{ marginBottom: "1rem" }}
                    >
                      {t("Beauty Program")}
                    </h3>
                    <p>
                      {t(
                        "Through our Remote Consultation function, customers now can talk and receive consultation directly from Korea's best specialists."
                      )}
                    </p>
                  </div>
                  <div className="wbox_img">
                    <div className="back_emp">
                      <div class="icon_wr">
                        <img src="/img/i-5.png" alt="" />
                      </div>
                      <p>{t("Search for Doctors")}</p>
                    </div>
                    <div className="back_emp">
                      <div class="icon_wr">
                        <img src="/img/i-6.png" alt="" />
                      </div>
                      <p>{t("Book Consultation")}</p>
                    </div>
                    <div className="back_emp">
                      <div class="icon_wr">
                        <img src="/img/i-7.png" alt="" />
                      </div>
                      <p>{t("Doctor Consultation")}</p>
                    </div>
                    <div className="back_emp">
                      <div class="icon_wr">
                        <img src="/img/i-8.png" alt="" />
                      </div>
                      <p>{t("Translation Support")}</p>
                    </div>
                  </div>
                  <Link
                    href="/beauty_program/remote"
                    locale={locale}
                    className="more relative"
                  >
                    {t("MORE")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="main_4 section">
            <div className="main_4_txt wrapper">
              <div className="dbox row">
                <div className="col-2">
                  <p>
                    {ReactHtmlParser(t("OYOUNG’s <span>STATISTICS</span>"))}
                  </p>
                </div>
                <div className="col-2 row">
                  <div className="col-3">
                    <sub>{t("Customers")}</sub>
                    <h3 className="count">{data.customers}</h3>
                  </div>
                  <div className="col-3">
                    <sub>{t("Partner")}</sub>
                    <h3 className="count">{data.partners}</h3>
                  </div>
                  <div className="col-3">
                    <sub>{t("Specialist")}</sub>
                    <h3 className="count">{data.specialists}</h3>
                  </div>
                </div>
              </div>
              <div className="main_4_partner my-1">
                <div className="owl-carousel partner_slide">
                  {partners.map((value, key) => (
                    <img
                      className="item img_fixed"
                      src={value}
                      alt=""
                      style={{
                        width: 213.33,
                        height: 95.28,
                        objectFit: "contain",
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="main_4_bottom">
                <div className="row wrapper fl-none">
                  <div className="col-2">
                    <p>
                      {ReactHtmlParser(
                        t(
                          "<b>As a forefront leader </b>in Healthcare and Beauty platform based on Trust and Value, We strive to become <b>a companion in your journey to achieve Healthy and Beautiful life</b>."
                        )
                      )}
                    </p>
                  </div>
                  <div className="col-2 text-center">
                    <a
                      href="/"
                      className="btn main_qna"
                      style={{ padding: "10px 40px" }}
                      onClick={this.onClick}
                    >
                      {t("Contact Us")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
          <footer className="section fp-auto-height">
            <div className="ft_wrap wrapper">
              <div className="row pt-1">
                <div className="col-2 fl-left mt-1 mb-1 ft_menu">
                  {/* <Link href="/about_us/overview" locale={locale} className="mr-1">
                {tFooter("Overview")}
              </Link>
              <Link href="/products/business" locale={locale} className="mr-1">
                {tFooter("About us")}
              </Link>
              <Link href="/beauty_program/selfie" locale={locale}>
                {tFooter("Review")}
              </Link> */}
                </div>
                <div className="col-2 fl-right mt- mb-2 text-right">
                  <a
                    href="https://www.facebook.com/oyoungbeautyvn/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sns"
                  >
                    <img src="/img/facebook.png" alt="Facebook" />
                  </a>
                  <a
                    href={`https://www.instagram.com/oyoung_beauty/?hl=${locale}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sns"
                  >
                    <img src="/img/insta.png" alt="Instagram" />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCZtAQ7DobYrwW-NTEIql-cA"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sns"
                  >
                    <img src="/img/youtube.png" alt="Youtube" />
                  </a>
                </div>
              </div>
              <div className="row mt-1 mb-1">
                <div className="">
                  <h5>{tFooter("Company Info")}</h5>
                  <ul className="">
                    <li className="fl-left mr-2">
                      {tFooter("OYOUNG BEAUTY CO., LTD")}
                    </li>
                    <li className="fl-left mr-2">{tFooter("CEO: Kim Eden")}</li>
                    <li className="fl-left mr-2">
                      {tFooter("Business registration number: 70687-01035")}
                    </li>
                    <li className="fl-left mr-2">
                      {tFooter("Vietnam Tax code: 0107648234")}
                    </li>
                    <li className="fl-left mr-2">
                      {tFooter("(+82) 70 8808 0888")}
                    </li>
                    <li className="fl-left mr-2">
                      {tFooter(
                        "Online sales registration number: 2019-강원춘천-0489"
                      )}
                    </li>
                    <li className="fl-left">
                      <RouterLink
                        to={filesLink[locale]}
                        target="_blank"
                        download
                      >
                        {tFooter("Privacy Statement")}
                      </RouterLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-3">
                  <h5>{tFooter("Headquarter")}</h5>
                  <span>
                    {tFooter(
                      "9F Gangnam N Tower, 129 Teheran-ro, Gangnam-gu, Seoul, Korea (06133) (+82) 70 8808 0888"
                    )}
                  </span>
                </div>
                <div className="col-3">
                  <h5>{tFooter("Vietnam (Hanoi)")}</h5>
                  <span>
                    {tFooter(
                      "2F ,Indochina Plaza, 241 Xuan Thuy Street, Dich Vong Hau Ward, Cau Giay, Ha Noi, Vietnam (+84 )24 7108 8810"
                    )}
                  </span>
                </div>
                <div className="col-3">
                  <h5>{tFooter("Vietnam (Ho Chi Minh)")}</h5>
                  <span>
                    {tFooter(
                      "136 Tran Quang Khai, Tan Dinh Ward, Dist 1, HCMC, Vietnam (+84) 36 848 1666 Hotline 24/7 (+84) 28 3848 1666"
                    )}
                  </span>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col py-1 my-1">
                  <span>
                    {tFooter(
                      "Copyright©Oyoung Beauty Co. Ltd. All rights reserved."
                    )}
                  </span>
                </div>
              </div>
            </div>
          </footer>
        </div>
        <Popup ref={(ref) => (this.ref = ref)} />
      </React.Fragment>
    );
  }
}

export default App;

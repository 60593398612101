import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale } from "hocs";
import Footer from "layouts/Footer";
import { importScripts } from "utils";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

@withLocale
@withConnect((state) => ({}))
@withTranslation("about_us.business")
class App extends React.Component {
  async componentDidMount() {
    importScripts(this);
  }
  render() {
    const { locale, t } = this.props;
    return (
      <div className="sub about">
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          `
          }
          `}
        </style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/about_us/overview" locale={locale}>
                  {t("About us")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/remote" locale={locale}>
                      {t("Beauty Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partners")}
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/about_us/business" locale={locale}>
                  {t("Our Business")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/about_us/overview" locale={locale}>
                      {t("Overview")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/about_us/vision" locale={locale}>
                      {t("Vision")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/about_us/business" locale={locale}>
                      {t("Our Business")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/about_us/ceo" locale={locale}>
                      {t("CEO")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual">
          <div className="sub_txt wrapper relative">
            <h2>{t("About us")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "As a leading leader in beauty and health platforms based on trust and value, <span>I want to be a healthy and beautiful partner for customers.</span>"
                )
              )}
            </p>
          </div>
        </div>

        <div className="page">
          <div className="sub_cont py-2" id="business">
            <div className="first relative wrapper">
              <div className="text-center">
                <h2 className="sub_title">{t("Our Business")}</h2>
              </div>
            </div>
            <div className="mid relative ">
              <ul className="text-center tabs relative">
                <li>
                  <a href="#tab1" className="tab p1">
                    {t("IT")}
                  </a>
                </li>
                <li>
                  <a href="#tab2" className="tab p1">
                    {t("Health")}
                  </a>
                </li>
              </ul>

              <div className="page tab_container">
                <div id="tab1" className="tab_content">
                  <div className="page_title text-center">
                    <h2>{t("IT")}</h2>
                    <p>
                      {t(
                        "We provide IT solutions for which are included here."
                      )}
                    </p>
                  </div>

                  <div className="wrapper" data-aos="fade-up">
                    <h3 className="cont_title">{t("O'YOUNG Talk")}</h3>
                    <div className="row row_column_list">
                      <div className="col-3 row_column_box">
                        <h4>{t("Directly Communication")}</h4>
                        <p>
                          {t(
                            "O'young application allows doctors and customers to communicate directly on healthcare and beauty. Customers can send the same request to several doctors at the same time."
                          )}
                        </p>
                      </div>
                      <div className="col-3 row_column_box">
                        <h4>{t("Customer Needs")}</h4>
                        <p>
                          {t(
                            "Customers can send photos, videos, documents through O'young Talk so that doctors can understand better customer needs."
                          )}
                        </p>
                      </div>
                      <div className="col-3 row_column_box">
                        <h4>{t("Built-in Translation Capability")}</h4>
                        <p>
                          {t(
                            "Customer and Doctor do not need translator or interpretation as O'young Talk provides you a built-in translation capability."
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row row_column_list">
                      <div className="col-3 row_column_box">
                        <h4>{t("Price Quote Comparison")}</h4>
                        <p>
                          {t(
                            "Customer can get price quotation and compare with other offers."
                          )}
                        </p>
                      </div>
                      <div className="col-3 row_column_box">
                        <h4>{t("User Attention System")}</h4>
                        <p>
                          {t(
                            "O'young Talk sends notifications to users if there is any specific occasion that requires user attention"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="cont_wr sect">
                    <div className="wrapper">
                      <div className="mb-3">
                        <h3 className="cont_title">
                          {t("Remote Medical Consultation")}
                        </h3>
                        <ul className="dash">
                          <li>
                            {t(
                              "O'Young application allows doctors, customers, and coordinators to have remote medical consultation wherever they are."
                            )}
                          </li>
                          <li>
                            {t(
                              "O'Young IT technology makes the customer feel as if they are at real consultation with the doctor."
                            )}
                          </li>
                          <li>
                            {t("This technology have three unique components.")}
                          </li>
                        </ul>
                      </div>
                      <div className="ic-area row" data-aos="fade-up">
                        <div className="col-3 text-center ic_area_wr">
                          <div className="ic_inner center">
                            <img src="/img/sub/sub1-3_ic1.png" alt="" />
                          </div>
                          <h4 className="ic_area_name">
                            {ReactHtmlParser(
                              t("Virtual outpatient<br />Schedule Management")
                            )}
                          </h4>
                          <p>
                            {t(
                              "The doctor opens the outpatient schedule. Customers can book a consultation schedule."
                            )}
                          </p>
                        </div>
                        <div className="col-3 text-center ic_area_wr">
                          <div className="ic_inner center">
                            <img src="/img/sub/sub1-3_ic2.png" alt="" />
                          </div>
                          <h4
                            className="ic_area_name"
                            style={{ height: locale !== "en" ? "unset" : 60 }}
                          >
                            {t("Consultation")}
                          </h4>
                          <p>
                            {t(
                              "During the consultation, the doctor can have the customer’s information synchronizing with Digital Health peripherals such as blood pressure, BMI, X-ray that allow the doctor to have a more accurate diagnosis and recommendation."
                            )}
                          </p>
                        </div>
                        <div className="col-3 text-center ic_area_wr">
                          <div className="ic_inner center">
                            <img src="/img/sub/sub1-3_ic3.png" alt="" />
                          </div>
                          <h4
                            className="ic_area_name"
                            style={{ height: locale !== "en" ? "unset" : 60 }}
                          >
                            {t("Report")}
                          </h4>
                          <p>
                            {t(
                              "Consultation reports can be captured from remote medical consultations and can be shared with the doctor and customer."
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sect">
                    <div className="wrapper">
                      <div className="col">
                        <h3 className="cont_title">
                          {t("Speech Recognition Consultation Report")}
                        </h3>
                        <p>
                          {ReactHtmlParser(
                            t(
                              'By O\'young speech recognition and content summarization technology, consultation reports can be generated automatically for reference and further actions. Speech recognition is conducted by reading the video feed of remote consultation. Both customer and doctor <span className="pink">do not need to summarize the consultation</span>.'
                            )
                          )}
                        </p>
                        <img
                          src="/img/sub/sub1-3_mid.jpg"
                          alt=""
                          data-aos="fade-up"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="cont_wr sect ">
                    <div className="wrapper">
                      <div className="col mb-2">
                        <h3 className="cont_title">
                          {t("Clinic Rating based on Big Data and Algorithm")}
                        </h3>
                        <p>
                          {ReactHtmlParser(
                            t(
                              "Our technology helps customers select the right clinics from true ratings, reviews, and feedback excluding advertisements. <br />We use the following process from IT."
                            )
                          )}
                        </p>
                      </div>
                      <div
                        className="row row_column_list"
                        data-aos="fade-right"
                      >
                        <div
                          className="col-3 text-center row_column_box row_column_auto"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ margin: 0 }}>
                            {ReactHtmlParser(
                              t(
                                '<span className="black">Collect Data </span>from many different sources including social networks excluding <span className="black">Ads</span>.'
                              )
                            )}
                          </p>
                        </div>
                        <div
                          className="col-3 text-center row_column_box row_column_auto"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ margin: 0 }}>
                            {ReactHtmlParser(
                              t(
                                '<span className="black">Conduct Data </span>analysis'
                              )
                            )}
                          </p>
                        </div>
                        <div
                          className="col-3 text-center row_column_box row_column_auto"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ margin: 0 }}>
                            {ReactHtmlParser(
                              t(
                                '<span className="black">Data visualization </span>using algorithm.'
                              )
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sect wrapper">
                    <div className="col">
                      <h3 className="cont_title">{t("Smart Vital")}</h3>
                      <p style={{ marginBottom: 10 }}>
                        {t(
                          "O'young IT technology integrates with smart peripheral to show and store customers health data on O'young application such as:"
                        )}
                      </p>
                      <ul className="dash">
                        <li>{t("BMI, VAI")}</li>
                        <li>{t("Blood pressure")}</li>
                        <li>
                          {t("Blood test and 4 types of DNA test analysis")}
                        </li>
                        <li>{t("Toxic and Essential Elements Profiles")}</li>
                        <li>{t("Food Intolerance Profiles")}</li>
                      </ul>
                    </div>
                    <div
                      className="ic-area row"
                      data-aos="fade-up"
                      style={{ marginTop: "2em" }}
                    >
                      <div className="col-3 text-center">
                        <img
                          src="/img/sub/sub4_sv1.jpg"
                          alt=""
                          className="img_fixed"
                          style={{
                            width: 311,
                            height: 167,
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="col-3 text-center">
                        <img
                          src="/img/sub/sub4_sv2.jpg"
                          alt=""
                          className="img_fixed"
                          style={{
                            width: 311,
                            height: 167,
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="col-3 text-center">
                        <img
                          src="/img/sub/sub4_sv3.jpg"
                          alt=""
                          className="img_fixed"
                          style={{
                            width: 311,
                            height: 167,
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div id="tab2" className="tab_content">
                  <div className="page_title text-center">
                    <h2>{t("Health")}</h2>
                    <p>
                      {t(
                        "We provide Healthcare solutions products and services which are included here."
                      )}
                    </p>
                  </div>

                  <div className="wrapper">
                    <div className="col text-left mb-3 pb-2" data-aos="fade-up">
                      <h3 className="cont_title">
                        {t("Products for B2B Partners")}
                      </h3>
                      <p className="mb-2">
                        {t(
                          "Partner can purchase the most effective Stem Cell and NK Cell origin functional cosmetics for whitening, anti-aging, acne care, scar healing, and hair loss solution for their customers on our B2B platform."
                        )}
                      </p>
                      <img src="/img/sub/sub1-3_mid2.jpg" alt="" />
                    </div>
                    <div className="col text-left mb-3 pb-2" data-aos="fade-up">
                      <h3 className="cont_title">{t("Healthcare Program")}</h3>
                      <p className="mb-2">
                        {ReactHtmlParser(
                          t(
                            "O'YOUNG Health Program is created by understanding the health concerns of our family and people we love. <br />Customers can experience Korea's advanced Re-generation and Immunity Program at O'young Beauty."
                          )
                        )}
                      </p>
                      <img src="/img/sub/sub1-3_mid3.jpg" alt="" />
                    </div>
                    <div className="col text-left mb-3 pb-2" data-aos="fade-up">
                      <h3 className="cont_title">{t("Remote Consultation")}</h3>
                      <p className="mb-2">
                        {ReactHtmlParser(
                          t(
                            "We provide Remote Consultation for customers to talk directly with the best medical team in Korea on their Health and Beauty enhancement needs on our platform. <br />Prior consultation and diagnosis will lead to better effective and successful treatment."
                          )
                        )}
                      </p>
                      <img src="/img/sub/sub1-3_mid4.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;

import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale, withSuspense } from "hocs";
import Footer from "layouts/Footer";
import { importScripts } from "utils";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import chunk from "lodash.chunk";

@withLocale
@withTranslation("partners.partner")
@withConnect((state) => ({}))
@withSuspense
class App extends React.Component {
  static getStaticProps = async () => {
    let { data } = await axios.get("/json/partners.partner.json");
    return { data };
  };
  async componentDidMount() {
    importScripts(this);
  }
  render() {
    const { locale, t, data } = this.props;
    return (
      <div className="sub partner">
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          `
          }
          `}
        </style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/partners/partner" locale={locale}>
                  {t("Partners")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link
                      href="/about_us/overview"
                      locale={locale}
                      className="nav_link"
                    >
                      {t("About us")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/remote" locale={locale}>
                      {t("Beauty Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("Products")}
                    </Link>
                  </li>
                </ul> */}
              </li>

              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/partners/partner" locale={locale}>
                  {t("Partner Portable")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partner Portable")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual sub_v2">
          <div className="sub_txt wrapper relative">
            <h2>{t("Partners")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "We focus on creating a trusted partner ecosystem of healthcare experts <span>who are committed to the success of your health and beauty treatments.</span>"
                )
              )}
            </p>
          </div>
        </div>

        <div className="page">
          <div className="sub_cont py-2 wrapper" id="partners">
            <div className="first relative">
              <div className="text-center">
                <h2 className="sub_title">{t("Partner Portable")}</h2>
              </div>
              <div className="title_img relative" data-aos="fade-up">
                <img src="/img/sub/sub2_mid.jpg" alt="" />
                <div className="wbox absolute text-center">
                  <p>
                    {ReactHtmlParser(
                      t(
                        "collaborates with verified healthcare service providers in Korea and <span>regional countries to provide health and beauty treatments </span><span>for customers with the highest standards of care, safety and quality.</span>"
                      )
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="mid relative">
              <ul className="text-center tabs relative">
                <li className="p1">
                  <a href="#tab1" className="tab p1">
                    {t("Korea")}
                  </a>
                </li>
                <li className="p1">
                  <a href="#tab2" className="tab p1">
                    {t("Vietnam")}
                  </a>
                </li>
              </ul>

              <div className="page tab_container">
                <div id="tab1" className="tab_content">
                  <div className="page_title text-center">
                    <h2>{t("Korea")}</h2>
                    <p>
                      {ReactHtmlParser(
                        t(
                          "O'YOUNG's partner can publish its stroy and also sell products and services <span>to other partners on O'YOUNG's application through Partner Portal.</span>"
                        )
                      )}
                    </p>
                    <a
                      target="_blank"
                      href="https://partner.oyoung.co.kr/consulting/login.html"
                      className="btn my-2"
                    >
                      {t("Join Us")}
                      <i className="arrow right"></i>
                    </a>
                  </div>

                  <div className="logos_wr" data-aos="fade-up">
                    {chunk(data["korea"], 6).map((datas, key) => (
                      <div className="row mb-2" key={key}>
                        {datas.map((value, key) => (
                          <div className="col-6" key={key}>
                            <img
                              src={value}
                              alt=""
                              className="img_fixed"
                              style={{
                                width: 197,
                                height: 119,
                                objectFit: "contain",
                                border: "1px solid #F1F2F4",
                                borderRadius: 5,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>

                <div id="tab2" className="tab_content">
                  <div className="page_title text-center">
                    <h2>{t("Vietnam")}</h2>
                    <p>
                      {ReactHtmlParser(
                        t(
                          "O'YOUNG's partner can publish its stroy and also sell products and services <span>to other partners on O'YOUNG's application through Partner Portal.</span>"
                        )
                      )}
                    </p>
                    <a
                      target="_blank"
                      href="https://partner.oyoung.co.kr/consulting/login.html"
                      className="btn my-2"
                    >
                      {t("Join Us")}
                      <i className="arrow right"></i>
                    </a>
                  </div>

                  <div className="logos_wr" data-aos="fade-up">
                    {chunk(data["vietnam"], 6).map((datas, key) => (
                      <div className="row mb-2" key={key}>
                        {datas.map((value, key) => (
                          <div className="col-6" key={key}>
                            <img
                              src={value}
                              alt=""
                              className="img_fixed"
                              style={{
                                width: 197,
                                height: 119,
                                objectFit: "contain",
                                border: "1px solid #F1F2F4",
                                borderRadius: 5,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;

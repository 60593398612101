import React from "react";
import { withLocale } from "hocs";
import { withTranslation } from "react-i18next";
import { Link } from "components";
import { Link as RouterLink } from "react-router-dom";

const filesLink = {
  en: "/files/Privacy Statement in English.pdf",
  vi: "/files/Privacy Statement in Vietnamese.pdf",
  ko: "/files/Privacy Statement in Korean.pdf",
};

@withLocale
@withTranslation("footer")
class Footer extends React.Component {
  render() {
    const { locale, t } = this.props;
    return (
      <footer className="section fp-auto-height">
        <div className="ft_wrap wrapper">
          <div className="row pt-1">
            <div className="col-2 fl-left mt-1 mb-1 ft_menu">
              {/* <Link href="/about_us/overview" locale={locale} className="mr-1">
                {t("Overview")}
              </Link>
              <Link href="/products/business" locale={locale} className="mr-1">
                {t("About us")}
              </Link>
              <Link href="/beauty_program/selfie" locale={locale}>
                {t("Review")}
              </Link> */}
            </div>
            <div className="col-2 fl-right mt- mb-2 text-right">
              <a
                href="https://www.facebook.com/oyoungbeautyvn/"
                target="_blank"
                rel="noopener noreferrer"
                className="sns"
              >
                <img src="/img/facebook.png" alt="Facebook" />
              </a>
              <a
                href={`https://www.instagram.com/oyoung_beauty/?hl=${locale}`}
                target="_blank"
                rel="noopener noreferrer"
                className="sns"
              >
                <img src="/img/insta.png" alt="Instagram" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCZtAQ7DobYrwW-NTEIql-cA"
                target="_blank"
                rel="noopener noreferrer"
                className="sns"
              >
                <img src="/img/youtube.png" alt="Youtube" />
              </a>
            </div>
          </div>
          <div className="row mt-1 mb-1">
            <div className="">
              <h5>{t("Company Info")}</h5>
              <ul className="">
                <li className="fl-left mr-2">{t("OYOUNG BEAUTY CO., LTD")}</li>
                <li className="fl-left mr-2">{t("CEO: Kim Eden")}</li>
                <li className="fl-left mr-2">
                  {t("Business registration number: 70687-01035")}
                </li>
                <li className="fl-left mr-2">
                  {t("Vietnam Tax code: 0107648234")}
                </li>
                <li className="fl-left mr-2">{t("(+82) 70 8808 0888")}</li>
                <li className="fl-left mr-2">
                  {t("Online sales registration number: 2019-강원춘천-0489")}
                </li>
                <li className="fl-left">
                  <RouterLink to={filesLink[locale]} target="_blank" download>
                    {t("Privacy Statement")}
                  </RouterLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3">
              <h5>{t("Headquarter")}</h5>
              <span>
                {t(
                  "9F Gangnam N Tower, 129 Teheran-ro, Gangnam-gu, Seoul, Korea (06133) (+82) 70 8808 0888"
                )}
              </span>
            </div>
            <div className="col-3">
              <h5>{t("Vietnam (Hanoi)")}</h5>
              <span>
                {t(
                  "2F ,Indochina Plaza, 241 Xuan Thuy Street, Dich Vong Hau Ward, Cau Giay, Ha Noi, Vietnam (+84 )24 7108 8810"
                )}
              </span>
            </div>
            <div className="col-3">
              <h5>{t("Vietnam (Ho Chi Minh)")}</h5>
              <span>
                {t(
                  "136 Tran Quang Khai, Tan Dinh Ward, Dist 1, HCMC, Vietnam (+84) 36 848 1666 Hotline 24/7 (+84) 28 3848 1666"
                )}
              </span>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col py-1 my-1">
              <span>
                {t("Copyright©Oyoung Beauty Co. Ltd. All rights reserved.")}
              </span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

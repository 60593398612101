import React from "react";
import ReactDOM from "react-dom";
import { withLocale } from "hocs";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Link } from "components";

const { $, AOS } = window;

@withRouter
@withLocale
@withTranslation("header")
class Header extends React.Component {
  async componentDidMount() {
    this.animate();
    const { waitLocale } = this.props;
    await waitLocale();
    this.initLocale();
  }
  initLocale = () => {
    const { locale } = this.props;
    const data = {
      en: {
        langItem_w: 45,
        langItem_p: 45.416669999999954,
      },
      vi: {
        langItem_w: 38,
        langItem_p: 91.06254402343757,
      },
      ko: {
        langItem_w: 45,
        langItem_p: 0.00004402343745368853,
      },
    };
    let $langItem_box = $(".active_box");
    ["en", "vi", "ko"]
      .map((key) => ({
        key,
        node: ReactDOM.findDOMNode(this[key]),
      }))
      .forEach(({ key, node }) => {
        if (key !== locale) return;
        // let langItem_w = parseInt($(node).innerWidth());
        // let langItem_p = $(node).position().left;
        let { langItem_w, langItem_p } = data[key];
        $langItem_box.animate(
          {
            width: langItem_w + "px",
            left: langItem_p + "px",
          },
          300
        );
      });
  };
  animate = () => {
    let header = $("header");
    let lang = $(".nav_lang .nav_lang_item");
    let list = $("ul.nav_list");

    this.list = list;
    this.list.hover(function () {
      if (header.hasClass("open")) {
        header.removeClass("open");
      } else {
        header.addClass("open");
      }
    });

    lang.click(function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
      } else {
        $(this).addClass("active");
        $(this).siblings(lang).removeClass("active");
      }
    });

    var $langItem_box = $(".active_box"),
      langItem_w = "",
      langItem_p = "";
    lang.on("click", function () {
      langItem_w = parseInt($(this).innerWidth());
      langItem_p = $(this).position().left;
      $langItem_box.animate(
        {
          width: langItem_w + "px",
          left: langItem_p + "px",
        },
        300
      );
    });

    let hamburger = $(".nav_hamburger"),
      mo = $(".nav_mo");
    hamburger.click(function () {
      hamburger.toggleClass("open");
      mo.toggleClass("open");
    });

    let list_item = $(".nav_hover_item");
    list_item.click(function () {
      hamburger.toggleClass("open");
      mo.toggleClass("open");
      let closest = $(this).closest(".nav_hover_wr");
      closest.addClass("nav_hover_wr_click");
      setTimeout(() => {
        closest.removeClass("nav_hover_wr_click");
      }, 0);
    });

    let first_li = $(".nav_mo_ul .nav_link"),
      first_ul = $(".nav_mo_ul > li.relative");
    first_li.click(function () {
      if ($(this).parent(first_ul).hasClass("active")) {
        $(this).parent(first_ul).removeClass("active");
      } else {
        first_li.parent(first_ul).removeClass("active");
        $(this).parent(first_ul).addClass("active");
      }
    });

    let self = this;
    $(function () {
      AOS.init({
        duration: 1200,
      });
      self.onElementHeightChange(document.body, function () {
        AOS.refresh();
      });
    });
  };
  onElementHeightChange = (elm, callback) => {
    var lastHeight = elm.clientHeight;
    var newHeight;

    (function run() {
      newHeight = elm.clientHeight;
      if (lastHeight !== newHeight) callback();
      lastHeight = newHeight;

      if (elm.onElementHeightChangeTimer) {
        clearTimeout(elm.onElementHeightChangeTimer);
      }

      elm.onElementHeightChangeTimer = setTimeout(run, 200);
    })();
  };
  componentWillUnmount() {
    if (this.list && this.list.unbind)
      this.list.unbind("mouseenter mouseleave");
  }
  render() {
    const {
      locale = "en",
      t,
      location: { pathname },
    } = this.props;
    const getPath = () => pathname.replace(/^(\/en|\/vi|\/ko)/, "");
    return (
      <header className="fixed-top">
        <div className="nav absolute">
          <Link href="/" locale={locale} className="logo fl-left mr-4 pr-4">
            <img src="/img/logo.png" alt="logo" />
          </Link>
          <ul className="nav_list fl-left ml-4 pl-4">
            <li className="relative">
              <Link
                href="/about_us/overview"
                locale={locale}
                className="nav_link"
              >
                {t("About us")}
              </Link>
              <ul className="nav_hover_wr wr1">
                <li>
                  <Link
                    href="/about_us/overview"
                    locale={locale}
                    className="nav_hover_item"
                  >
                    {t("Overview")}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/about_us/vision"
                    locale={locale}
                    className="nav_hover_item"
                  >
                    {t("Vision")}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/about_us/business"
                    locale={locale}
                    className="nav_hover_item"
                  >
                    {t("Our Business")}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/about_us/ceo"
                    locale={locale}
                    className="nav_hover_item"
                  >
                    {t("CEO")}
                  </Link>
                </li>
              </ul>
            </li>
            <li className="relative">
              <Link
                href="/health_program/health"
                locale={locale}
                className="nav_link"
              >
                {t("Health Program")}
              </Link>
              <ul className="nav_hover_wr wr2">
                <li>
                  <Link
                    href="/health_program/health"
                    locale={locale}
                    className="nav_hover_item"
                  >
                    {t("O'YOUNG Health Program")}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/health_program/health_review"
                    locale={locale}
                    className="nav_hover_item"
                  >
                    {t("Real Experience Review")}
                  </Link>
                </li>
              </ul>
            </li>
            <li className="relative">
              <Link
                href="/beauty_program/remote"
                locale={locale}
                className="nav_link"
              >
                {t("Beauty Program")}
              </Link>
              <ul className="nav_hover_wr wr3">
                <li>
                  <Link
                    href="/beauty_program/remote"
                    locale={locale}
                    className="nav_hover_item"
                  >
                    {t("Remote Consultation")}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/beauty_program/recommend"
                    locale={locale}
                    className="nav_hover_item"
                  >
                    {t("Recommend Doctors")}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/beauty_program/selfie"
                    locale={locale}
                    className="nav_hover_item"
                  >
                    {t("Real Selfie Review")}
                  </Link>
                </li>
              </ul>
            </li>
            <li className="relative">
              <Link
                href="/products/products"
                locale={locale}
                className="nav_link"
              >
                {t("Products")}
              </Link>
              <ul className="nav_hover_wr wr4">
                <li>
                  <Link
                    href="/products/products"
                    locale={locale}
                    className="nav_hover_item"
                  >
                    {t("O'YOUNG Brand")}
                  </Link>
                </li>
              </ul>
            </li>
            <li className="relative">
              <Link
                href="/partners/partner"
                locale={locale}
                className="nav_link"
              >
                {t("Partners")}
              </Link>
              <ul className="nav_hover_wr wr5">
                <li>
                  <Link
                    href="/partners/partner"
                    locale={locale}
                    className="nav_hover_item"
                  >
                    {t("Partner Portable")}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <div className="nav_list nav_lang fl-left ml-4">
            <span className="active_box"></span>
            <Link
              href={getPath()}
              locale="ko"
              className={`nav_lang_item ${locale === "ko" ? "active" : ""}`}
              ref={(ref) => (this.ko = ref)}
            >
              KOR
            </Link>
            <span className="dash"></span>
            <Link
              href={getPath()}
              locale="en"
              className={`nav_lang_item ${locale === "en" ? "active" : ""}`}
              ref={(ref) => (this.en = ref)}
            >
              ENG
            </Link>
            <span className="dash"></span>
            <Link
              href={getPath()}
              locale="vi"
              passHref
              className={`nav_lang_item ${locale === "vi" ? "active" : ""}`}
              ref={(ref) => (this.vi = ref)}
            >
              VIE
            </Link>
          </div>
        </div>
        <div className="nav_hamburger relative fl-right">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="nav_mo relative">
          <div className="nav_mo_menu relative">
            <ul className="nav_mo_ul">
              <li className="relative">
                <a href="javascript:void(0)" className="nav_link">
                  {t("About us")}
                  <i className="arrow up fl-right"></i>
                </a>
                <ul className="nav_hover_wr">
                  <li>
                    <Link
                      href="/about_us/overview"
                      locale={locale}
                      className="nav_hover_item"
                    >
                      {t("Overview")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/about_us/vision"
                      locale={locale}
                      className="nav_hover_item"
                    >
                      {t("Vision")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/about_us/business"
                      locale={locale}
                      className="nav_hover_item"
                    >
                      {t("Our Business")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/about_us/ceo"
                      locale={locale}
                      className="nav_hover_item"
                    >
                      {t("CEO")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="relative">
                <a href="javascript:void(0)" className="nav_link">
                  {t("Health Program")}
                  <i className="arrow up fl-right"></i>
                </a>
                <ul className="nav_hover_wr">
                  <li>
                    <Link
                      href="/health_program/health"
                      locale={locale}
                      className="nav_hover_item"
                    >
                      {t("O'YOUNG Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/health_program/health_review"
                      locale={locale}
                      className="nav_hover_item"
                    >
                      {t("Real Experience Review")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="relative">
                <a href="javascript:void(0)" className="nav_link">
                  {t("Beauty Program")}
                  <i className="arrow up fl-right"></i>
                </a>
                <ul className="nav_hover_wr">
                  <li>
                    <Link
                      href="/beauty_program/remote"
                      locale={locale}
                      className="nav_hover_item"
                    >
                      {t("Remote Consultation")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/beauty_program/recommend"
                      locale={locale}
                      className="nav_hover_item"
                    >
                      {t("Recommend Doctors")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/beauty_program/selfie"
                      locale={locale}
                      className="nav_hover_item"
                    >
                      {t("Real Selfie Review")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="relative">
                <a href="javascript:void(0)" className="nav_link">
                  {t("Products")}
                  <i className="arrow up fl-right"></i>
                </a>
                <ul className="nav_hover_wr">
                  <li>
                    <Link
                      href="/products/products"
                      locale={locale}
                      className="nav_hover_item"
                    >
                      {t("O'YOUNG Brand")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="relative">
                <a href="javascript:void(0)" className="nav_link">
                  {t("Partners")}
                  <i className="arrow up fl-right"></i>
                </a>
                <ul className="nav_hover_wr">
                  <li>
                    <Link
                      href="/partners/partner"
                      locale={locale}
                      className="nav_hover_item"
                    >
                      {t("Partner Portable")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="nav_lang">
              <span className="active_box"></span>
              <Link
                href={getPath()}
                locale="ko"
                className={`nav_lang_item ${locale === "ko" ? "active" : ""}`}
              >
                KOR
              </Link>
              <span className="dash"></span>
              <Link
                href={getPath()}
                locale="en"
                className={`nav_lang_item ${locale === "en" ? "active" : ""}`}
              >
                ENG
              </Link>
              <span className="dash"></span>
              <Link
                href={getPath()}
                locale="vi"
                className={`nav_lang_item ${locale === "vi" ? "active" : ""}`}
              >
                VIE
              </Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;

import React from "react";
import { Form } from "antd";
import EventEmitter from "events";

class ModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
    this.event = new EventEmitter();
    props.forwardRef(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.onClick);
  }
  onClick = (e) => {
    let { visible } = this.state;
    if (visible && this.ref && !this.ref.contains(e.target)) this.cancelEvent();
  };
  eventPromise = (eventName) => {
    return new Promise((resolve) => {
      this.event.once(eventName, resolve);
    });
  };
  onSubmit = () => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState(
          {
            visible: true,
          },
          async () => {
            let [eventName, values] = await Promise.race([
              new Promise(async (resolve, reject) => {
                let values = await this.eventPromise("ok");
                resolve(["ok", values]);
              }),
              new Promise(async (resolve, reject) => {
                await this.eventPromise("cancel");
                resolve(["cancel"]);
              }),
            ]);
            if (eventName === "ok") resolve(values);
            else reject();
            this.event.removeAllListeners("ok");
            this.event.removeAllListeners("cancel");
          }
        );
      } catch (error) {
        reject();
      }
    });
  };
  okEvent = () => {
    const { form } = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        this.event.emit("ok", values);
        this.setState({ visible: false });
      }
    });
  };
  cancelEvent = () => {
    const { form } = this.props;
    this.event.emit("cancel");
    this.setState({ visible: false }, form.resetFields);
  };
  componentWillUnmount() {
    if (this.event) {
      this.event.removeAllListeners("ok");
      this.event.removeAllListeners("cancel");
    }
    document.removeEventListener("mousedown", this.handleClick);
  }
  render() {
    const { form, children } = this.props;
    const { visible } = this.state;
    return (
      <Form onSubmit={this.okEvent}>
        {children({
          form,
          visible,
          onSubmit: this.okEvent,
          onCancel: this.cancelEvent,
          ref: (ref) => (this.ref = ref),
        })}
      </Form>
    );
  }
}

export const Modal = Form.create()(ModalForm);

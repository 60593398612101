//Trim
export const trimObject = (value, parentType, parentValue, key) => {
  if (Array.isArray(value)) {
    for (const [key, element] of value.entries()) {
      trimObject(element, "array", value, key);
    }
    return;
  }
  if (typeof value === "object") {
    for (const key in value) {
      trimObject(value[key], "object", value, key);
    }
    return;
  }
  if (typeof value === "string") {
    if (["array", "object"].includes(parentType)) {
      parentValue[key] = value.trim();
      return;
    }
  }
};

export const importScripts = async (context, isMain = false) => {
  if (isMain || !window) return;
  const { $ } = window;

  window.scrollTo(0, 0);

  const {
    props: { waitLocale },
  } = context;
  await waitLocale();
  let header = $("header");
  let lang_wr = $(".nav_lang");
  let lang = $(".nav_lang .nav_lang_item");
  let active = $(".nav_lang_item.active");

  try {
    header.css("background", "rgba(255,255,255)");
    lang_wr.css("border-color", "#a1a1a0");
    lang.css("color", "#a1a1a0");
    // active.css("color", "#fff");
  } catch (error) {}

  {
    $(".tab_content").hide();
    $(".tabs li:first").addClass("active").show();
    $(".tab_content:first").show();

    $(".tabs li").click(function () {
      $(".tabs li").removeClass("active");
      $(this).addClass("active");
      $(".tab_content").hide();

      var activeTab = $(this).find("a").attr("href");
      $(activeTab).fadeIn();
      return false;
    });

    $(".prd_related").owlCarousel({
      items: 5,
      nav: true,
      margin: 10,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 5,
        },
      },
    });
    const up = $(".brd_doc_intro_header > .arrow.up.fl-right");
    const cont = $(".brd_doc_intro_cont");
    const down = $(".one_column_open");
    const review = $(".one_column_cont");

    up.click(function () {
      if (up.hasClass("active")) {
        up.removeClass("active");
        cont.css("max-height", "1px");
        cont.css("padding", "0");
      } else {
        up.addClass("active");
        cont.css("max-height", "464px");
        cont.css("padding", "2em");
      }
    });

    down.click(function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(this).prev(review).css("max-height", "12em");
      } else {
        $(this).prev(review).css("max-height", "42em");
        $(this).addClass("active");
      }
    });

    const cate = $(".sm_category > a");
    cate.click(function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
      } else {
        $(this).addClass("active");
        $(this).siblings($("a")).removeClass("active");
      }
    });

    const prdMenu = $(".tab_content .page_title .category .col .dep_btn");
    prdMenu.click(function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
      } else {
        $(this).addClass("active");
        $(this).siblings(prdMenu).removeClass("active");
      }
    });
  }
};

export const getPagination = (array, configs) => {
  const { hierarchy = [], filter = [], pagination = {} } = configs;

  filter.forEach((fn) => (array = array.filter(fn)));
  const $where = function (value) {
    if (value === undefined) return { ...this };
    return { ...this[value] };
  };
  const $pagination = function (currentPage = 1, size = pagination.size) {
    const self = this;
    let data = {
      currentPage,
      size,
      prev: function (size = pagination.size) {
        return this.page(this.currentPage - 1, size);
      },
      next: function (size = pagination.size) {
        return this.page(this.currentPage + 1, size);
      },
      page: function (indexPage, size = pagination.size) {
        if (
          !size ||
          indexPage < 1 ||
          self.array.length <= (indexPage - 1) * size
        ) {
          return this;
        }
        return self.$pagination(indexPage, size);
      },
    };
    delete data.$pagination;
    data["array"] = !!size
      ? this["array"].slice((currentPage - 1) * size, currentPage * size)
      : [...this["array"]];
    data["totalPage"] =
      size && this.array.length ? Math.ceil(this.array.length / size) : 1;
    return data;
  };
  const $filter = function (fnFilter) {
    let data = {
      ...this,
    };
    Object.keys(this).forEach((key) => {
      if (["$where", "$pagination", "$filter", "tree"].includes(key)) return;
      if (key === "array") {
        return (data[key] = this[key].filter(fnFilter));
      }
      data[key] = {
        ...this[key],
        array: this[key]["array"].filter(fnFilter),
      };
    });
    return data;
  };
  let hierarchyData = {
    array,
    tree: [],
    $where,
    $pagination,
    $filter,
  };
  const handleHierarchy = (hierarchy, index, hierarchyData) => {
    if (!hierarchy[index]) return;
    let { key, values, fnReturn } = hierarchy[index];
    const { array, tree } = hierarchyData;
    index = index + 1;
    if (fnReturn) {
      values = values || Array.from(new Set(array.map(fnReturn)));
    }
    values.forEach((value) => {
      hierarchyData[value] = {
        array: array.filter((v) => {
          if (fnReturn) {
            return fnReturn(v) === value || fnReturn(v)?.includes(value);
          }
          return v[key] === value || v[key]?.includes(value);
        }),
        tree: [...tree, value],
        $where,
        $pagination,
        $filter,
      };
      return handleHierarchy(hierarchy, index, hierarchyData[value]);
    });
  };
  handleHierarchy(hierarchy, 0, hierarchyData);
  hierarchyData["tree"] = [undefined];
  return hierarchyData;
};

export const splitString = (array, key, string) => {
  let clone = [...array];
  clone.forEach((value) => {
    value[key] =
      typeof value[key] === "string"
        ? value[key].split(string).filter((_) => _)
        : [];
  });
  return clone;
};

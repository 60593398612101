import React from "react";
import EventEmitter from "events";

const LocaleContext = React.createContext({
  locale: undefined,
  changeLocale: () => {},
  isLoaded: false,
  waitLocale: () => {},
});

export class LocaleProvider extends React.Component {
  state = { locale: undefined, isLoaded: false };
  event = new EventEmitter();
  eventPromise = (eventName) => {
    return new Promise((resolve) => {
      this.event.once(eventName, resolve);
    });
  };
  changeLocale = (locale) => {
    this.setState({ locale, isLoaded: true }, () => {
      this.event.emit("loaded");
    });
  };
  waitLocale = () => {
    return new Promise(async (resolve, reject) => {
      const { isLoaded } = this.state;
      if (!isLoaded) await this.eventPromise("loaded");
      resolve();
    });
  };
  render() {
    const { children } = this.props;
    const { locale, isLoaded } = this.state;
    return (
      <LocaleContext.Provider
        value={{
          locale,
          changeLocale: this.changeLocale,
          isLoaded,
          waitLocale: this.waitLocale,
        }}
      >
        {children}
      </LocaleContext.Provider>
    );
  }
}

export const withLocale = (Component) => {
  return React.forwardRef((props, ref) => (
    <LocaleContext.Consumer>
      {({ locale, changeLocale, isLoaded, waitLocale }) => (
        <Component
          locale={locale}
          changeLocale={changeLocale}
          isLoaded={isLoaded}
          waitLocale={waitLocale}
          {...props}
          ref={ref}
        />
      )}
    </LocaleContext.Consumer>
  ));
};

import React from "react";
import { Modal, Item } from "components";
import { useTranslation } from "react-i18next";

const Popup = React.forwardRef((props, ref) => {
  const { t } = useTranslation("common");
  return (
    <Modal {...props} forwardRef={ref}>
      {({ form: { getFieldDecorator }, onSubmit, onCancel, visible, ref }) => (
        <div className={`modal_wr ${visible ? "active" : ""}`}>
          <div className="dark_layer dark_layer_main"></div>
          <div className="modal absolute p3" ref={ref}>
            <a
              className="close absolute"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                onCancel();
              }}
            >
              <img src="/img/sub/close.png" alt="close" />
            </a>
            <div className="text-center">
              <h2>{t("Consultation Reservation")}</h2>
              <p>{t("We will contact within 24h.")}</p>
            </div>
            <table>
              <tr>
                <td>{t("Name")}</td>
                <td>
                  <Item>
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: t("Require Name"),
                        },
                      ],
                    })(<input type="text" className="p1" />)}
                  </Item>
                </td>
              </tr>
              <tr>
                <td>{t("Country")}</td>
                <td>
                  <Item>
                    {getFieldDecorator("country", {
                      rules: [
                        {
                          required: true,
                          message: t("Require Country"),
                        },
                      ],
                    })(<input type="text" className="p1" />)}
                  </Item>
                </td>
              </tr>
              <tr>
                <td>{t("Phone")}</td>
                <td>
                  <Item>
                    {getFieldDecorator("phone", {
                      rules: [
                        {
                          required: true,
                          message: t("Require Phone"),
                        },
                      ],
                    })(<input type="text" className="p1" />)}
                  </Item>
                </td>
              </tr>
              <tr>
                <td>{t("Message")}</td>
                <td>
                  <Item>
                    {getFieldDecorator(
                      "message",
                      {}
                    )(
                      <textarea
                        className="p1"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        placeholder={t("Write down your inquiry here.")}
                      />
                    )}
                  </Item>
                </td>
              </tr>
            </table>
            <input
              type="submit"
              className="btn"
              value={t("Send Request")}
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  );
});

export default Popup;

const axios = require("axios");
const { CancelToken } = axios;

export const sendGet = (url, params, _token, headers = {}) => {
  let cancel;
  let promise = new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      params,
      headers: {
        ...headers,
        ...(_token
          ? {
              Authorization: `Bearer ${_token}`,
              "Accept-Language": "vi",
            }
          : {}),
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
  promise.cancel = cancel;
  return promise;
};

export const sendPost = (url, params, data, _token, headers = {}) => {
  let cancel;
  let promise = new Promise((resolve, reject) => {
    axios({
      method: "post",
      url,
      data: data,
      params,
      headers: {
        ...headers,
        ...(_token
          ? {
              Authorization: `Bearer ${_token}`,
              "Accept-Language": "vi",
            }
          : {}),
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });

  promise.cancel = cancel;
  return promise;
};

export const sendPut = (url, params, data, _token, headers = {}) => {
  let cancel;
  let promise = new Promise((resolve, reject) => {
    axios({
      method: "put",
      url,
      params,
      data: data,
      headers: {
        ...headers,
        ...(_token
          ? {
              Authorization: `Bearer ${_token}`,
              "Accept-Language": "vi",
            }
          : {}),
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });

  promise.cancel = cancel;
  return promise;
};

export const sendPatch = (url, params, data, _token, headers = {}) => {
  let cancel;
  let promise = new Promise((resolve, reject) => {
    axios({
      method: "patch",
      url,
      data: data,
      params,
      headers: {
        ...headers,
        ...(_token
          ? {
              Authorization: `Bearer ${_token}`,
              "Accept-Language": "vi",
            }
          : {}),
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });

  promise.cancel = cancel;
  return promise;
};

export const sendDelete = (url, params, _token, headers) => {
  let cancel;
  let promise = new Promise((resolve, reject) => {
    axios({
      method: "delete",
      url,
      params,
      headers: {
        ...headers,
        ...(_token
          ? {
              Authorization: `Bearer ${_token}`,
              "Accept-Language": "vi",
            }
          : {}),
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
  promise.cancel = cancel;
  return promise;
};

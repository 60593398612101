import React from "react";
import { Modal, Item } from "components";
import { useTranslation } from "react-i18next";

const Popup = React.forwardRef((props, ref) => {
  const { t } = useTranslation("common");
  return (
    <Modal {...props} forwardRef={ref}>
      {({ form: { getFieldDecorator }, onSubmit, onCancel, visible, ref }) => (
        <div className={`modal_wr fixed-top ${visible ? "active" : ""}`}>
          <div className="dark_layer"></div>
          <div className="modal absolute p3" style={{ height: 715 }} ref={ref}>
            <a
              className="close absolute"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                onCancel();
              }}
            >
              <img src="/img/sub/close.png" alt="close" />
            </a>
            <form action="">
              <div className="text-center">
                <h2>{t("Help Desk")}</h2>
                <p>{t("Write an inquiry about the product.")}</p>
              </div>
              <table>
                <tr>
                  <td>{t("Name")}</td>
                  <td>
                    <Item>
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: t("Require Name"),
                          },
                        ],
                      })(<input type="text" className="p1" />)}
                    </Item>
                  </td>
                </tr>
                <tr>
                  <td>{t("Country")}</td>
                  <td>
                    <Item>
                      {getFieldDecorator("country", {
                        rules: [
                          {
                            required: true,
                            message: t("Require Country"),
                          },
                        ],
                      })(<input type="text" className="p1" />)}
                    </Item>
                  </td>
                </tr>
                <tr>
                  <td>{t("Phone")}</td>
                  <td>
                    <Item>
                      {getFieldDecorator("phone", {
                        rules: [
                          {
                            required: true,
                            message: t("Require Phone"),
                          },
                        ],
                      })(<input type="text" className="p1" />)}
                    </Item>
                  </td>
                </tr>
                <tr>
                  <td>{t("E-mail")}</td>
                  <td>
                    <Item>
                      {getFieldDecorator(
                        "email",
                        {}
                      )(<input type="text" className="p1" />)}
                    </Item>
                  </td>
                </tr>
                <tr>
                  <td>{t("Message")}</td>
                  <td>
                    <Item>
                      {getFieldDecorator(
                        "message",
                        {}
                      )(
                        <textarea
                          className="p1"
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          placeholder={t("Write down your inquiry here.")}
                        />
                      )}
                    </Item>
                  </td>
                </tr>
              </table>
              <input
                type="submit"
                className="btn"
                value={t("Send Message")}
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              />
            </form>
          </div>
        </div>
      )}
    </Modal>
  );
});

export default Popup;

import { sendGet } from "utils/requests";
import notification from "utils/notification";
import emailjs from "emailjs-com";

const {
  REACT_APP_ENPOINT,
  REACT_APP_EMAILJS_USER_ID,
  REACT_APP_EMAILJS_SERVICE_ID,
  REACT_APP_EMAILJS_TEMPLATE_ID,
} = process.env;

export const commonGetStatistics = () => (dispatch) => {
  return sendGet(`${REACT_APP_ENPOINT}/api/stats`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      if (typeof window !== "undefined") notification.errorStrict(err);
      throw err;
    });
};

export const commonGetRecommendedConsultants = () => (dispatch) => {
  return sendGet(`${REACT_APP_ENPOINT}/api/consultants/recommended`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      if (typeof window !== "undefined") notification.errorStrict(err);
      throw err;
    });
};

export const commonGetConsultantById = (uid) => (dispatch) => {
  return sendGet(`${REACT_APP_ENPOINT}/api/consultants/${uid}`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      if (typeof window !== "undefined") notification.errorStrict(err);
      throw err;
    });
};

export const commonGetProducts = (params) => (dispatch) => {
  return sendGet(`${REACT_APP_ENPOINT}/api/products`, params)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      if (typeof window !== "undefined") notification.errorStrict(err);
      throw err;
    });
};

export const commonGetProductById = (uid) => (dispatch) => {
  return sendGet(`${REACT_APP_ENPOINT}/api/products/${uid}`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      if (typeof window !== "undefined") notification.errorStrict(err);
      throw err;
    });
};

export const commonGetCustomerReviews = (params) => (dispatch) => {
  return sendGet(`${REACT_APP_ENPOINT}/api/customerReviews`, params)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      if (typeof window !== "undefined") notification.errorStrict(err);
      throw err;
    });
};

export const commonGetCustomerReviewsById = (uid) => (dispatch) => {
  return sendGet(`${REACT_APP_ENPOINT}/api/customerReviews/${uid}`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      if (typeof window !== "undefined") notification.errorStrict(err);
      throw err;
    });
};

export const commonContactFormSubmission = (data) => async (dispatch) => {
  return emailjs
    .send(
      REACT_APP_EMAILJS_SERVICE_ID,
      REACT_APP_EMAILJS_TEMPLATE_ID,
      data,
      REACT_APP_EMAILJS_USER_ID
    )
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale } from "hocs";
import Footer from "layouts/Footer";
import { importScripts } from "utils";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

@withLocale
@withTranslation("beauty_program.remote")
@withConnect((state) => ({}))
class App extends React.Component {
  async componentDidMount() {
    importScripts(this);
  }
  render() {
    const { locale, t } = this.props;
    return (
      <div className="sub beauty">
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          `
          }
          `}
        </style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/beauty_program/remote" locale={locale}>
                  {t("Beauty Program")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link
                      href="/about_us/overview"
                      locale={locale}
                      className="nav_link"
                    >
                      {t("About us")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partners")}
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/beauty_program/remote" locale={locale}>
                  {t("Remote Consultation")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/beauty_program/remote" locale={locale}>
                      {t("Remote Consultation")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/recommend" locale={locale}>
                      {t("Recommend doctors")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/selfie" locale={locale}>
                      {t("Review")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual">
          <div className="sub_txt wrapper relative">
            <h2>{t("Beauty Program")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "Talk with the best Korean specialists and <br />receive medical consultation anywhere with O'young application"
                )
              )}
            </p>
          </div>
        </div>

        <div className="page">
          <div className="sub_cont pt-2 pb-4 wrapper" id="">
            <div className="first relative">
              <div className="text-center">
                <h2 className="sub_title">{t("Remote Consultation")}</h2>
              </div>
              <div className="title_img relative" data-aos="fade-up">
                <img src="/img/sub/sub5_mid.jpg" alt="" className="my-1 beauty_01_title_img" />
              </div>
            </div>
            <div className="remote">
              <div className="row col_list mb-4" data-aos="fade-up">
                <div className="col-4 text-center">
                  <div className="col_list_wr">
                    <h3 className="number text-center">{t("01")}</h3>
                    <h4 className="col_list_title">{t("Search for Doctor")}</h4>
                    <p>
                      {t(
                        "Sign in O'young application, and search for the doctors who are suitable for your needs and concerns."
                      )}
                    </p>
                    <img
                      src="/img/sub/sub5_bot1.png"
                      alt=""
                      className="img_fixed"
                      style={{
                        width: 194,
                        height: 408,
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="col_list_wr">
                    <h3 className="number text-center">{t("02")}</h3>
                    <h4 className="col_list_title">{t("Book Schedule")}</h4>
                    <p>
                      {t(
                        "Select to start chatting with the doctor or simply fill in the video consultation form and book a schedule at your convenient time."
                      )}
                    </p>
                    <img
                      src="/img/sub/sub5_bot2.png"
                      alt=""
                      className="img_fixed"
                      style={{
                        width: 194,
                        height: 408,
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="col_list_wr">
                    <h3 className="number text-center">{t("03")}</h3>
                    <h4 className="col_list_title">
                      {t("Doctor Consultation")}
                    </h4>
                    <p>
                      {t(
                        "Have a talk session directly with the doctor in your mother language with our translator support."
                      )}
                    </p>
                    <img
                      src="/img/sub/sub5_bot3.png"
                      alt=""
                      className="img_fixed"
                      style={{
                        width: 194,
                        height: 408,
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="col_list_wr">
                    <h3 className="number text-center">{t("04")}</h3>
                    <h4 className="col_list_title">
                      {t("Treatment Suggestion")}
                    </h4>
                    <p>
                      {t(
                        "With the consultation record and customer health data, doctor can plan the treatment schedule and coordinator will send quotations to the customer."
                      )}
                    </p>
                    <img
                      src="/img/sub/sub5_bot4.png"
                      alt=""
                      className="img_fixed"
                      style={{
                        width: 194,
                        height: 408,
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;

import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale } from "hocs";
import Footer from "layouts/Footer";
import { importScripts } from "utils";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

@withLocale
@withTranslation("about_us.vision")
@withConnect((state) => ({}))
class App extends React.Component {
  async componentDidMount() {
    importScripts(this);
  }
  render() {
    const { locale, t } = this.props;
    return (
      <div className="sub about">
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          `
          }
          `}
        </style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/about_us/overview" locale={locale}>
                  {t("About us")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partners")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/remote" locale={locale}>
                      {t("Beauty Program")}
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/about_us/vision" locale={locale}>
                  {t("Vision")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/about_us/overview" locale={locale}>
                      {t("Overview")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/about_us/vision" locale={locale}>
                      {t("Vision")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/about_us/business" locale={locale}>
                      {t("Our Business")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/about_us/ceo" locale={locale}>
                      {t("CEO")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual">
          <div className="sub_txt wrapper relative">
            <h2>{t("About us")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "As a leading leader in beauty and health platforms based on trust and value, <span>I want to be a healthy and beautiful partner for customers.</span>"
                )
              )}
            </p>
          </div>
        </div>

        <div className="page">
          <div className="sub_cont py-2 wrapper" id="vision">
            <div className="first relative">
              <div className="text-center">
                <h2 className="sub_title">{t("Vision")}</h2>
              </div>
              <div className="title_img relative" data-aos="fade-up">
                <img src="/img/sub/sub1-2_mid.jpg" alt="" className="my-1" />
                <div className="wbox absolute text-center">
                  <p>
                    {ReactHtmlParser(
                      t(
                        "Forefront Leader in Health and Beauty Platform by Developing <span>Trust and Delivering Value as a Companion in your care journey.</span>"
                      )
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="mid relative text-center"
              id="vision"
              data-aos="fade-up"
            >
              <div className="row">
                <div className="col-2">
                  <h3 className="mid_title">{t("Trust and Value")}</h3>
                  <div className="ic_mold center">
                    <img
                      src="/img/sub/sub1-2_ic1.png"
                      alt=""
                      className="my-1"
                    />
                  </div>
                  <p>
                    {ReactHtmlParser(
                      t(
                        'O\'young Beauty is built on the foundation of <span className="black">Trust</span>. Trust is of utmost importance in O\'young Beauty’s culture and is developed by delivering our own <span className="black">Value </span>to customers and partners with unrivaled vision, innovation, and execution.'
                      )
                    )}
                  </p>
                </div>
                <div className="col-2">
                  <h3 className="mid_title">
                    {t("Health and Beauty Platform")}
                  </h3>
                  <div className="ic_mold center">
                    <img
                      src="/img/sub/sub1-2_ic2.png"
                      alt=""
                      className="my-1"
                    />
                  </div>
                  <p>
                    {ReactHtmlParser(
                      t(
                        'We believe <span className="black">Healthcare and Beauty </span>lead to wellness and happiness. O\'young connects and engages with customers who are seeking health and beauty treatments delivered by healthcare experts who provide the highest standards of care, safety, and quality.'
                      )
                    )}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <h3 className="mid_title">{t("Forefront Leader")}</h3>
                  <div className="ic_mold center">
                    <img
                      src="/img/sub/sub1-2_ic3.png"
                      alt=""
                      className="my-1"
                    />
                  </div>
                  <p>
                    {ReactHtmlParser(
                      t(
                        "O'young strives to be the <span className=\"black\">forefront leader </span>to create a health and beauty ecosystem via cooperation with certified healthcare service providers in Korea and regional countries which have passed stringent screening criteria verified personally by our O'young team."
                      )
                    )}
                  </p>
                </div>
                <div className="col-2">
                  <h3 className="mid_title">{t("Companion")}</h3>
                  <div className="ic_mold center">
                    <img src="/img/sub/sub1-2_ic4.png" alt="" />
                  </div>
                  <p>
                    {ReactHtmlParser(
                      t(
                        'O\'young is committed to be <span className="black">the key Companion </span>to support your path towards wellness and happiness by ensuring customer needs are met with the best digital and physical experience across online, social and in-person interactions throughout the care journey of your health and beauty treatment.'
                      )
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { withLocale } from "hocs";

export const Link = withLocale(
  React.forwardRef((props, ref) => {
    const { href, locale = "" } = props;
    return (
      <RouterLink
        {...props}
        to={`${locale && `/${locale}`}${href}`}
        ref={ref}
      />
    );
  })
);

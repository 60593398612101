import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale } from "hocs";
import Footer from "layouts/Footer";
import { importScripts } from "utils";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

@withLocale
@withTranslation("about_us.overview")
@withConnect((state) => ({}))
class App extends React.Component {
  async componentDidMount() {
    importScripts(this);
  }
  render() {
    const { locale, t } = this.props;
    return (
      <div className="sub about">
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          `
          }
          `}
        </style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/about_us/overview" locale={locale}>
                  {t("About us")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/remote" locale={locale}>
                      {t("Beauty Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partners")}
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/about_us/overview" locale={locale}>
                  {t("Overview")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/about_us/overview" locale={locale}>
                      {t("Overview")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/about_us/vision" locale={locale}>
                      {t("Vision")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/about_us/business" locale={locale}>
                      {t("Our Business")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/about_us/ceo" locale={locale}>
                      {t("CEO")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual">
          <div className="sub_txt wrapper relative">
            <h2>{t("About us")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "As a leading leader in beauty and health platforms based on trust and value, <span>I want to be a healthy and beautiful partner for customers.</span>"
                )
              )}
            </p>
          </div>
        </div>

        <div className="page">
          <div className="sub_cont py-2 wrapper" id="overview">
            <div className="">
              <div className="first relative">
                <div className="text-center">
                  <h2 className="sub_title">{t("Overview")}</h2>
                </div>
                <div className="title_img relative" data-aos="fade-up">
                  <img src="/img/sub/sub1_mid.jpg" alt="" />
                  <div className="wbox absolute text-center">
                    <p>
                      {ReactHtmlParser(
                        t(
                          "Connecting people around the world who are seeking <span>healthcare, beauty enhancement, and plastic surgery services in Korea.</span>"
                        )
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mid relative">
                <h2 className="mb-2">{t("Why O'YOUNG?")}</h2>
                <div className="tile">
                  <div className="row">
                    <div
                      className="col-2 p4 center txtbox "
                      data-aos="fade-left"
                    >
                      <sub>{t("Why O'YOUNG 01")}</sub>
                      <h3>
                        {ReactHtmlParser(
                          t(
                            "<span>Magnificent Performances in </span><br />Improvement Your Health & Beauty"
                          )
                        )}
                      </h3>
                      <p>
                        {t(
                          "O'Young Health Program is created by empathizing and understanding the health concerns of our family and loved ones."
                        )}
                      </p>
                      <p>
                        {ReactHtmlParser(
                          t(
                            "O'Young Beauty is striving to allow customers <br />to experience regeneration and immunity improvement programs developed based on the latest cell technology and the best capabilities of Korea."
                          )
                        )}
                      </p>
                    </div>
                    <div className="col-2">
                      <img src="/img/sub/sub1_bot1.jpg" alt="" />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-2 fl-right p4 center txtbox"
                      data-aos="fade-right"
                    >
                      <sub>{t("Why O'YOUNG 02")}</sub>
                      <h3>
                        {ReactHtmlParser(
                          t(
                            "<span>Korea and Local Partner </span><br />ECO-SYSTEMS"
                          )
                        )}
                      </h3>
                      <p>
                        {t(
                          "We focus on creating a trusted partner ecosystem of healthcare experts who are committed to the success of your health and beauty treatments."
                        )}
                      </p>
                    </div>
                    <div className="col-2 fl-right">
                      <img src="/img/sub/sub1_bot2.jpg" alt="" />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-2 p4 center txtbox"
                      data-aos="fade-left"
                    >
                      <sub>{t("Why O'YOUNG 03")}</sub>
                      <h3>{ReactHtmlParser(t("Services <br />END-to-END"))}</h3>
                      <ul></ul>
                      <p>
                        {t(
                          "We provide an end-to-end platform delivering comprehensive Health and Beauty services for the best-in-className customer experience."
                        )}
                      </p>
                      <p>
                        {t(
                          "Customers can search and match the best doctors by having remote consultations."
                        )}
                      </p>
                      <p>
                        {t(
                          "They also receive treatment suggestions and service quotations directly from the hospital coordinator, can book treatment schedules, and manage health data."
                        )}
                      </p>
                    </div>
                    <div className="col-2">
                      <img src="/img/sub/sub1_bot3.jpg" alt="" />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-2 fl-right p4 center txtbox"
                      data-aos="fade-right"
                    >
                      <sub>{t("Why O'YOUNG 04")}</sub>
                      <h3>
                        {ReactHtmlParser(
                          t(
                            "<span>Based on Technology and Human Intelligent, </span><br />Service Provider Qualification"
                          )
                        )}
                      </h3>
                      <p>
                        {t(
                          "Qualified professionalism is the key DNA of O'young Beauty. We use both technology and human intelligence to verify again and again on our Service Providers."
                        )}
                      </p>
                      <p>
                        {t(
                          "We verify hospital, clinic, and doctor license data with the Korea Ministry of Health. In addition, we analyze all available feedback and review of the hospital and doctors as well as directly visit the place to experience their services as customers."
                        )}
                      </p>
                    </div>
                    <div className="col-2 fl-right">
                      <img src="/img/sub/sub1_bot4.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="last cont_wr sect">
            <div className="wrapper">
              <div className="last_title text-center mb-3">
                <h2>{t("Service")}</h2>
                <p>
                  {ReactHtmlParser(
                    t(
                      "Our innovative business models and outstanding technology provide <span>to customer and partner the following specific services.</span>"
                    )
                  )}
                </p>
              </div>

              <div className="last_list service_ic">
                <ul>
                  <li className="py-1">
                    <span className="number">{t("01")}</span>
                    <span>
                      {t(
                        "Partner can purchase the most effective cell origin functional cosmetic for whitening, anti-aging, acne care, scar healing, and hair loss solution for their customers on our B2B platform."
                      )}
                    </span>
                    <div className="ic_inner center">
                      <img src="/img/sub/sub1_ic1.png" alt="" />
                    </div>
                  </li>
                  <li className="py-1">
                    <span className="number">{t("02")}</span>
                    <span>
                      {t(
                        "Customer can have Remote Medical Consultation with the enhancement needs on our platform."
                      )}
                    </span>
                    <div className="ic_inner center">
                      <img src="/img/sub/sub1_ic2.png" alt="" />
                    </div>
                  </li>
                  <li className="py-1">
                    <span className="number">{t("03")}</span>
                    <span>
                      {t(
                        "Customer can experience Korea's advanced Re-generation and Immunity Program."
                      )}
                    </span>
                    <div className="ic_inner center">
                      <img src="/img/sub/sub1_ic3.png" alt="" />
                    </div>
                  </li>
                  <li className="py-1">
                    <span className="number">{t("04")}</span>
                    <span>
                      {t(
                        "Health check and health screening for Cancer and Disease"
                      )}
                    </span>
                    <div className="ic_inner center">
                      <img src="/img/sub/sub1_ic4.png" alt="" />
                    </div>
                  </li>
                  <li className="py-1">
                    <span className="number">{t("05")}</span>
                    <span>
                      {t(
                        "Private designed Healthcare and Beauty program to improve Health and Lifestyle"
                      )}
                    </span>
                    <div className="ic_inner center">
                      <img src="/img/sub/sub1_ic5.png" alt="" />
                    </div>
                  </li>
                  <li className="py-1">
                    <span className="number">{t("06")}</span>
                    <span>
                      {t(
                        "Face contouring, breast implant, lifting, eye, nose job and more"
                      )}
                    </span>
                    <div className="ic_inner center">
                      <img src="/img/sub/sub1_ic6.png" alt="" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;

import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale } from "hocs";
import { commonContactFormSubmission } from "redux/actions";
import { trimObject, importScripts } from "utils";
import Footer from "layouts/Footer";
import notification from "utils/notification";
import { withTranslation, Trans } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import Popup from "./Popup";

@withLocale
@withTranslation("health_program.health")
@withConnect((state) => ({}))
class App extends React.Component {
  async componentDidMount() {
    importScripts(this);
  }
  onClick = async (e) => {
    e.preventDefault();
    if (!this.ref) return;
    try {
      const { dispatch } = this.props;
      let values = await this.ref.onSubmit();
      trimObject(values);
      try {
        await dispatch(commonContactFormSubmission(values));
        return notification.success("Success");
      } catch (error) {
        return notification.error("Fail");
      }
    } catch (error) {}
  };
  render() {
    const { locale, t } = this.props;
    return (
      <React.Fragment>
        <style>{`
          #health .qna_btn:hover::after {content: "${t(
            "OCP Consultation Reservation"
          )}"; ${locale === "vi" ? "padding: 0;" : ""}}
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 47px;}
          .row .stages.stage2.center p {width: 80%; font-size: 15px;}
          .row .dash {font-size: 13px;}
          .tabs li:last-child p, .tabs li:nth-child(5) p {font-size: 15px;}
          .stage_lg {font-size: 15px;}
          .text_overflow{font-size: 14px; width: 80%;}
          `
          }
        `}</style>
        <div className="sub health">
          <div className="sub_location">
            <div className="sub_location_wr wrapper relative">
              <ul className="breadcrumb">
                <li className="1dep fl-left relative">
                  <Link href="/health_program/health" locale={locale}>
                    {t("Health Program")}
                    {/* <span className="arrow_bd relative">
                      <i className="arrow down absolute" />
                    </span> */}
                  </Link>
                  {/* <ul className="absolute">
                    <li>
                      <Link
                        href="/about_us/overview"
                        locale={locale}
                        className="nav_link"
                      >
                        {t("About us")}
                      </Link>
                    </li>
                    <li>
                      <Link href="/beauty_program/remote" locale={locale}>
                        {t("Beauty Program")}
                      </Link>
                    </li>
                    <li>
                      <Link href="/products/products" locale={locale}>
                        {t("Products")}
                      </Link>
                    </li>
                    <li>
                      <Link href="/partners/partner" locale={locale}>
                        {t("Partners")}
                      </Link>
                    </li>
                  </ul> */}
                </li>
                <li className="dash fl-left">
                  <i className="arrow right"></i>
                </li>
                <li className="2dep fl-left relative">
                  <Link href="/health_program/health" locale={locale}>
                    {t("O'YOUNG Health Program")}
                    <span className="arrow_bd relative">
                      <i className="arrow down absolute" />
                    </span>
                  </Link>
                  <ul className="absolute">
                    <li>
                      <Link href="/health_program/health" locale={locale}>
                        {t("O'YOUNG Health Program")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/health_program/health_review"
                        locale={locale}
                      >
                        {t("Real Experience Review")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="sub_visual">
            <div className="sub_txt wrapper relative">
              <h2>{t("Health Program")}</h2>
              <p>
                {ReactHtmlParser(
                  t(
                    "Starting with Empathy and Understanding, the program was created <span>with love and care for our family and loved ones.</span>"
                  )
                )}
              </p>
            </div>
          </div>

          <div className="page">
            <div className="sub_cont py-2" id="health">
              <div className="cont wrapper">
                <div className="first relative">
                  <div className="text-center">
                    <h2 className="sub_title">{t("O'YOUNG Health Program")}</h2>
                  </div>
                  <div className="title_img relative" data-aos="fade-up">
                    <img src="/img/sub/sub4_mid.jpg" alt="" />
                    <div className="wbox absolute text-center">
                      <p>
                        {ReactHtmlParser(
                          t(
                            "Understanding the worries and concerns of people we love is the <span>greatest motivation for us to launch the O'young Health Program.</span>"
                          )
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative">
                <div className="qna_btn center" onClick={this.onClick}>
                  <a href="javascript:void(0)" className="center p2">
                    <img src="/img/sub/sub4_ic5.png" alt="" />
                  </a>
                </div>

                <div className="last_list sect">
                  <div className="wrapper">
                    <h2 className="mb-1 text-center">{t("OYOUNG’s Values")}</h2>
                    <ul>
                      <li className="py-1 row">
                        <div className=" col-7 center">
                          <div className="ic_inner center">
                            <img
                              className=""
                              src="/img/sub/sub4_ic1.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-1 ">
                          <h4>{t("Safety")}</h4>
                          <span>
                            {t(
                              "Health Program Research and Development team and their families are the first to participate and experience the program. Therefore, safety is the most important principle that cannot be compromised. Safety is verified again and again at every step of the program."
                            )}
                          </span>
                        </div>
                      </li>
                      <li className="py-1 row">
                        <div className=" col-7 center">
                          <div className="ic_inner center">
                            <img
                              className=""
                              src="/img/sub/sub4_ic2.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-1 ">
                          <h4>{t("Effectiveness")}</h4>
                          <span>
                            {t(
                              "No matter how good the seeds are sown, it is difficult to produce good fruit if the soil is poor. O'YOUNG maximizes the effectiveness of the program with a comprehensive prescription to improve the overall health of customers."
                            )}
                          </span>
                        </div>
                      </li>
                      <li className="py-1 row">
                        <div className=" col-7 center">
                          <div className="ic_inner center">
                            <img
                              className=""
                              src="/img/sub/sub4_ic3.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-1 ">
                          <h4>{t("Convenience")}</h4>
                          <span>
                            {t(
                              "Do you rarely read your medical test report because the content was so difficult to understand? Is it not easy to record and manage your health data after every treatment? Customers can now easily check their medical test results, treatment schedule and health status records all within the O'YOUNG application."
                            )}
                          </span>
                        </div>
                      </li>
                      <li className="py-1 row">
                        <div className=" col-7 center">
                          <div className="ic_inner center">
                            <img
                              className=""
                              src="/img/sub/sub4_ic4.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-1 ">
                          <h4>{t("Accessibility")}</h4>
                          <span>
                            {t(
                              "Health Program creates an ecosystem that can simplify the customer’s treatment process. We work hard to ensure that customers can benefit from our collaboration with many programs such as Homecare services, Umbilical Cord Stem cell therapy, Membrane-free Stem cell therapy, Intervention by Angiography and also Insurance program."
                            )}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="cont_wr sect">
                <h2 className="mb-1 text-center">
                  {t("Customer Care Process")}
                </h2>
                <div className="">
                  <div className="wrapper">
                    <h3 className="cont_title mb-1">
                      {t("1. Consultation and Preparation (1-12 weeks)")}
                    </h3>
                    <div className="process" data-aos="fade-right">
                      <div className="row">
                        <div className="stages stage0 center">
                          <img src="/img/sub/sub4_ic6.png" alt="" />
                          <p style={{ fontWeight: 400 }}>{t("Customer")}</p>
                        </div>
                        <div className="stages stage1 center">
                          <img src="/img/sub/sub4_ic7.png" alt="" />
                          <p style={{ textAlign: "center" }}>
                            {ReactHtmlParser(
                              t(
                                '<span className="black">Contact-Free Consultation </span><span>with O\'YOUNG coordinator</span>'
                              )
                            )}
                          </p>
                        </div>
                        <div className="stages stage2 center">
                          <img src="/img/sub/sub4_ic8.png" alt="" />
                          <p style={{ textAlign: "center" }}>
                            {ReactHtmlParser(
                              t(
                                'Visit <span className="black">O\'YOUNG Cell Center </span>/ <span className="black">Partner Hospital </span>or <span><span className="black">Homecare Service </span>staffs visit customer\'s home</span>'
                              )
                            )}
                          </p>
                        </div>
                        <div className="stages stage3 stage_lg center">
                          <img src="/img/sub/sub4_ic9.png" alt="" />
                          <p style={{ textAlign: "center" }}>
                            {ReactHtmlParser(
                              t(
                                'Basic <span className="black">Information </span>and <span className="black">Blood </span>test'
                              )
                            )}
                            <ul
                              className="dash text-center"
                              style={{ textAlign: "left" }}
                            >
                              <li>{t("Blood Tests")}</li>
                              <li>{t("Genetic Testing")}</li>
                              <li>
                                {t("Toxic and Essential Elements Profiles")}
                              </li>
                              <li>{t("Food Intolerance Profiles")}</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                      <div className="row inline-block">
                        <div className="stages stage4 center fl-right">
                          <img src="/img/sub/sub4_ic10.png" alt="" />
                          <p style={{ textAlign: "center" }}>
                            {ReactHtmlParser(
                              t(
                                'Test <span className="black">Analysis </span>and <span className="black">Report</span>'
                              )
                            )}
                          </p>
                        </div>
                        <div className="stages stage5 center">
                          <img src="/img/sub/sub4_ic11.png" alt="" />
                          <p style={{ textAlign: "center" }}>
                            {ReactHtmlParser(
                              t(
                                'Comeprehensive <span className="black">Prescription</span>'
                              )
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cont_detail sect">
                <div className="wrapper">
                  <div className="step">
                    <sub>{t("STEP 01")}</sub>
                    <h4 className="step_title">
                      {t("Remote Consultation with O'YOUNG’s Coordinator")}
                    </h4>
                    <p>
                      {ReactHtmlParser(
                        t(
                          'O\'YOUNG provides <span className="pink">Remote Consultation </span>to understand customer’s conditions and needs.'
                        )
                      )}
                    </p>
                  </div>
                  <div className="step">
                    <sub>{t("STEP 02")}</sub>
                    <h4 className="step_title">
                      {t("Basic Information and Blood Test")}
                    </h4>
                    <p>
                      {ReactHtmlParser(
                        t(
                          'The next step is collecting customer’s <span className="pink">basic vital information </span>and doing <span className="pink">blood test </span>.<br />Customers can choose either to directly visit O\'YOUNG Cell center or O\'YOUNG’s partner hospital, or the Homecare service medical staff will visit customer’s home or designated location. Basic vital information will be measured using smart devices that are linked to the O\'YOUNG application and customers can check their health status immediately.'
                        )
                      )}
                    </p>
                  </div>

                  <div className="step">
                    <sub>{t("STEP 03")}</sub>
                    <h4 className="step_title">
                      {t("Test Analysis and Report")}
                    </h4>
                    <p>
                      {t(
                        "Customers can view the following test results in the O'YOUNG application."
                      )}
                    </p>
                    <div
                      className="test_report one_column_list"
                      data-aos="fade-up"
                    >
                      <ul className="row tabs">
                        <li className="col-6 center">
                          <a
                            href="#tab1"
                            className="ic_inner ic_inner_12 center tab"
                          ></a>
                          <p>{t("Vital Information")}</p>
                        </li>
                        <li className="col-6 center">
                          <a
                            href="#tab2"
                            className="ic_inner ic_inner_13 center tab"
                          ></a>
                          <p>{t("Health Questionnaire")}</p>
                        </li>
                        <li className="col-6 center">
                          <a
                            href="#tab3"
                            className="ic_inner ic_inner_14 center tab"
                          ></a>
                          <p>{t("Blood Tests")}</p>
                        </li>
                        <li className="col-6 center">
                          <a
                            href="#tab4"
                            className="ic_inner ic_inner_15 center tab"
                          ></a>
                          <p>{t("Genetic Testing")}</p>
                        </li>
                        <li className="col-6 center">
                          <a
                            href="#tab5"
                            className="ic_inner ic_inner_16 center tab"
                          ></a>
                          <p>{t("Toxic and Essential Elements Profiles")}</p>
                        </li>
                        <li className="col-6 center">
                          <a
                            href="#tab6"
                            className="ic_inner ic_inner_17 center tab"
                          ></a>
                          <p>{t("Food Intolerance Profiles")}</p>
                        </li>
                      </ul>
                      <div className="tab_container">
                        <div id="tab1" className="tab_content">
                          <div className="row p2">
                            <h5>{t("Vital Information")}</h5>
                            <br />
                            <p>
                              {t(
                                "We use smart devices integrated to the O'YOUNG application to collect health assessment data. Customers can view their current health status information such as body temperature, blood pressure, electrocardiogram, oxygen saturation, weight, body fat mass, visceral fat level and so on."
                              )}
                            </p>
                          </div>
                        </div>
                        <div id="tab2" className="tab_content">
                          <div className="row p2">
                            <h5>{t("Health Questionnaire")}</h5>
                            <br />
                            <p>
                              {t(
                                "Customers will answer a health questionnaire including medical history, use of medication and dietary supplements as well as what they wish to achieve through the program."
                              )}
                            </p>
                          </div>
                        </div>
                        <div id="tab3" className="tab_content">
                          <div className="row p2">
                            <h5>{t("Blood Tests")}</h5>
                            <br />
                            <p>
                              {t(
                                "Blood tests are performed to determine if the customer’s own fat tissues can be used for the cell culture. In addition, cancers and diseases can be prevented by testing for infection, potential cancers and NK Cell Activity."
                              )}
                            </p>
                          </div>
                        </div>
                        <div id="tab4" className="tab_content">
                          <div className="row p2">
                            <h5>{t("Genetic Testing")}</h5>
                            <br />
                            <p>
                              {t(
                                "Genetic testing is our service that screens customers for hereditary diseases and disorders, it can predict genetic risk for 12 major cancers and 23 common diseases such as dementia, cardiovascular disease and eye disease. It also helps customers to manage metabolic syndrome and have better health management with personalized gene-based advice for obesity, diet and exercise. Through Genetic Testing, the effectiveness of O'YOUNG Health Program can be maximized through disease prevention, early diagnosis and customized prescription."
                              )}
                            </p>
                          </div>
                        </div>
                        <div id="tab5" className="tab_content">
                          <div className="row p2">
                            <h5>
                              {t("Toxic and Essential Elements Profiles")}
                            </h5>
                            <br />
                            <p>
                              {t(
                                "Nowadays, people are excessively contaminated with toxic metals while minerals in food are increasingly scarce, causing the human body to absorb more toxic metals instead of minerals. When toxic metals enter the enzyme binding site that nutrient minerals must occupy, the enzyme function is inhibited or over-activated, causing disease. After analyzing the test results, we will present the method to detoxify toxic metals in the body to maximize the effectiveness of O'YOUNG Health Program."
                              )}
                            </p>
                          </div>
                        </div>
                        <div id="tab6" className="tab_content">
                          <div className="row p2">
                            <h5>{t("Food Intolerance Profiles")}</h5>
                            <br />
                            <p>
                              {t(
                                "This is the test of IgG antibody levels to more than 200 types of foods. A high concentration of IgG means that the body’s immune response to the food is strong. The effectiveness of O'YOUNG Stem Health Program can be maximized with a proposed diet plan based on the test result."
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="step mt-3">
                    <sub>{t("STEP 04")}</sub>
                    <h4 className="step_title">
                      {t("Comprehensive Prescription")}
                    </h4>
                    <p>
                      {ReactHtmlParser(
                        t(
                          "Customer’s health status information and test results are <span className=\"pink\">aggregated and analyzed by O'YOUNG’s AI system </span>and then compared with the clinical treatment database. We will then guide you through the comprehensive prescription upon consultation with O'YOUNG’s multidisciplinary medical team."
                        )
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="cont_wr sect">
                <div className="wrapper">
                  <h3 className="cont_title mb-1">
                    {t("2. Production and Treatment (1-12 weeks)")}
                  </h3>
                  <div className="process process2">
                    <div className="row" data-aos="fade-right">
                      <div className="stages stage1 center text-center">
                        <img src="/img/sub/sub4_ic18.png" alt="" />
                        <p>
                          {ReactHtmlParser(
                            t(
                              '<span className="black">Culture </span>/<span className="black">Production </span><span>with GMP approved facilities</span>'
                            )
                          )}
                        </p>
                      </div>
                      <div className="stages stage2 center">
                        <img src="/img/sub/sub4_ic19.png" alt="" />
                        <p>
                          <span className="black text-center">
                            {t("Safety Check")}
                          </span>
                        </p>
                        <div className="row">
                          <ul className="dash mr-1">
                            <li>{t("Endotoxin Test")}</li>
                            <li>{t("Mycoplasma Test")}</li>
                          </ul>
                          <ul className="dash">
                            <li>{t("Cell Count Test")}</li>
                            <li>{t("Sterility Test")}</li>
                          </ul>
                        </div>
                      </div>
                      <div className="stages stage3 center">
                        <img src="/img/sub/sub4_ic20.png" alt="" />
                        <p>
                          {ReactHtmlParser(
                            t(
                              '<span className="black">Delivery </span>using cold chain'
                            )
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row" data-aos="fade-left">
                      <div className="stages stage4 center fl-right">
                        <img src="/img/sub/sub4_ic22.png" alt="" />
                        <p className="text-center text_overflow">
                          {ReactHtmlParser(
                            t(
                              'Costomer visit <span className="black">O\'YOUNG Cell Center </span>/ <span className="black">Partner Hospital </span>or <span className="black">Homecare Service </span>staffs visit customer’s house'
                            )
                          )}
                        </p>
                      </div>
                      <div className="stages stage5 center">
                        <img src="/img/sub/sub4_ic21.png" alt="" />
                        <p>
                          {ReactHtmlParser(
                            t('<span className="black">Treatment</span>')
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cont_detail sect wrapper">
                <div className="step">
                  <sub>{t("STEP 01")}</sub>
                  <h4 className="step_title">
                    {t("Culture and Production with GMP Approved Facilities")}
                  </h4>
                  <p>
                    {ReactHtmlParser(
                      t(
                        'For quality and safety, stem cells are cultured only in facilities approved by the Food and Drug Administration (FDA) after obtaining <span className="pink">Good Manufacturing Practice (GMP) certification</span>.'
                      )
                    )}
                  </p>
                </div>
                <div className="step">
                  <sub>{t("STEP 02")}</sub>
                  <h4 className="step_title">{t("Safety Check")}</h4>
                  <p>
                    {ReactHtmlParser(
                      t(
                        'After the culture phase, stem cells must be <span className="pink">tested for safety </span>before being used for treatment.'
                      )
                    )}
                    <ul className="dash">
                      <li>{t("Endotoxin Test")}</li>
                      <li>{t("Mycoplasma Test")}</li>
                      <li>{t("Cell count Test")}</li>
                      <li>{t("Sterility Test")}</li>
                    </ul>
                  </p>
                </div>
                <div className="step">
                  <sub>{t("STEP 03")}</sub>
                  <h4 className="step_title">{t("Delivery")}</h4>
                  <p>
                    {t(
                      "Products will be safely delivered to O'YOUNG Cell Center or O'YOUNG’s partner hospitals using cold chain delivery to ensure product quality."
                    )}
                  </p>
                </div>
                <div className="step">
                  <sub>{t("STEP 04")}</sub>
                  <h4 className="step_title">{t("Treatment")}</h4>
                  <p>
                    {t(
                      "Treatment will be done by Intravenous injection (IV) or Direct injection (DI) according to the prescription at customer’s home or designated location."
                    )}
                  </p>
                </div>
              </div>
              <div className="only wrapper" data-aos="fade-up">
                <div className="text-center">
                  <h2 className="">{t("Only at O'YOUNG")}</h2>
                  <p>
                    {t(
                      "O'YOUNG is constantly striving to provide the most convenient services to customers."
                    )}
                  </p>
                </div>
                <div className="row py-3 mt-3 col_list">
                  <div className="col-3 text-center">
                    <div className="col_list_wr">
                      <h3 className="number text-center">{t("01")}</h3>
                      <p>
                        {t(
                          "We provide Remote medical consultation directly from Korea to customers anytime, anywhere."
                        )}
                      </p>
                      <img src="/img/sub/sub4_bot1.jpg" alt="" />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="col_list_wr">
                      <h3 className="number text-center">{t("02")}</h3>
                      <p>
                        {ReactHtmlParser(
                          t(
                            'All health information can be easily checked in the O\'YOUNG application. <span className="gray">Medical history, test results, treatment schedule and health status records</span>'
                          )
                        )}
                      </p>
                      <img src="/img/sub/sub4_bot2.jpg" alt="" />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="col_list_wr">
                      <h3 className="number text-center">{t("03")}</h3>
                      <p>
                        {t(
                          "With our Homecare services, customers can receive treatment comfortably at home."
                        )}
                      </p>
                      <img src="/img/sub/sub4_bot3.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Popup ref={(ref) => (this.ref = ref)} />
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default App;

import React from "react";
import "./styles.scss";
import { Link } from "components";
import { withConnect, withLocale, withSuspense } from "hocs";
import { trimObject } from "utils";
import Footer from "layouts/Footer";
import { importScripts } from "utils";
import Popup from "./Popup";
import notification from "utils/notification";
import { withTranslation } from "react-i18next";
import ellipsize from "ellipsize";
import ReactHtmlParser from "react-html-parser";
import {
  commonGetProducts,
  commonGetProductById,
  commonContactFormSubmission,
} from "redux/actions";

const { REACT_APP_ENPOINT } = process.env;

@withLocale
@withConnect((state) => ({}))
@withTranslation("products.products_view")
@withSuspense
class App extends React.Component {
  static getStaticProps = async ({ uid }) => {
    let data = await commonGetProductById(uid)();
    let products = await commonGetProducts({ category: "SHOP_BEAUTY" })();

    data.i18nProductName = data.i18nProductName
      ? JSON.parse(data.i18nProductName)
      : null;
    data.i18nShortProductName = data.i18nShortProductName
      ? JSON.parse(data.i18nShortProductName)
      : null;
    data.i18nDescription = data.i18nDescription
      ? JSON.parse(data.i18nDescription)
      : null;
    data.i18nSummary = data.i18nSummary ? JSON.parse(data.i18nSummary) : null;
    data.functions = data.details.functions;
    const functions = (data.functions.en || "")
      .split(/[/,]/)
      .filter((_) => _)
      .map((v) => v.trim());
    products = products
      .map((value) => ({
        ...value,
        i18nProductName: value.i18nProductName
          ? JSON.parse(value.i18nProductName)
          : null,
        i18nShortProductName: value.i18nShortProductName
          ? JSON.parse(value.i18nShortProductName)
          : null,
        i18nDescription: value.i18nDescription
          ? JSON.parse(value.i18nDescription)
          : null,
        functions: value.details.functions,
      }))
      .filter(
        (value) =>
          value.id !== data.id &&
          functions.some((v) => (value.functions.en || "").indexOf(v) !== -1)
      );

    return { data, products };
  };
  state = { src: null };
  async componentDidMount() {
    importScripts(this);
  }
  onClick = async (e) => {
    e.preventDefault();
    if (!this.ref) return;
    try {
      const { dispatch } = this.props;
      let values = await this.ref.onSubmit();
      trimObject(values);
      try {
        await dispatch(commonContactFormSubmission(values));
        return notification.success("Success");
      } catch (error) {
        return notification.error("Fail");
      }
    } catch (error) {}
  };
  onChange = (src) => (e) => {
    e.preventDefault();
    this.setState({ src });
  };
  render() {
    const { locale, t, data, products } = this.props;
    let { src } = this.state;
    src =
      src ||
      (data.banners &&
        data.banners[0] &&
        `${REACT_APP_ENPOINT}/upload/${data.banners[0].path}`);
    return (
      <div className="sub product">
        <style>
          {`
          ${
            locale === "en" &&
            `
          header .nav_lang .active_box {left: 47px;}
          `
          }
          ${
            locale === "vi" &&
            `
          header .nav_lang .active_box {left: 90px;}
          #product .page_title .row .col .dep_btn {width: calc(100%/8);}
          `
          }
          `}
        </style>
        <div className="sub_location">
          <div className="sub_location_wr wrapper relative">
            <ul className="breadcrumb">
              <li className="1dep fl-left relative">
                <Link href="/products/products" locale={locale}>
                  {t("Products")}
                  {/* <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span> */}
                </Link>
                {/* <ul className="absolute">
                  <li>
                    <Link
                      href="/about_us/overview"
                      locale={locale}
                      className="nav_link"
                    >
                      {t("About us")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/beauty_program/remote" locale={locale}>
                      {t("Beauty Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/health_program/health" locale={locale}>
                      {t("Health Program")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/partners/partner" locale={locale}>
                      {t("Partners")}
                    </Link>
                  </li>
                </ul> */}
              </li>

              <li className="dash fl-left">
                <i className="arrow right"></i>
              </li>
              <li className="2dep fl-left relative">
                <Link href="/products/products" locale={locale}>
                  {t("O'YOUNG Brand")}
                  <span className="arrow_bd relative">
                    <i className="arrow down absolute" />
                  </span>
                </Link>
                <ul className="absolute">
                  <li>
                    <Link href="/products/products" locale={locale}>
                      {t("O'YOUNG Brand")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub_visual sub_v2">
          <div className="sub_txt wrapper relative">
            <h2>{t("Products")}</h2>
            <p>
              {ReactHtmlParser(
                t(
                  "As a leading leader in beauty and health platforms based on trust and value, <span>I want to be a healthy and beautiful life partner for customers.</span>"
                )
              )}
            </p>
          </div>
        </div>

        <div className="page">
          <div className="sub_cont py-2 wrapper prd" id="product_view">
            <div className="row prd_top mb-3">
              <div className="col-2 relative">
                <div className="prd_view_img">
                  <img
                    src={src}
                    alt=""
                    className="img_fixed"
                    style={{
                      width: 630,
                      height: 784,
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="prd_view_img_thm absolute">
                  {data.banners.map((value, key) => (
                    <a
                      href="/"
                      className="thm_wr"
                      key={key}
                      onClick={this.onChange(
                        value.thumbnail &&
                          `${REACT_APP_ENPOINT}/upload/${value.thumbnail}`
                      )}
                    >
                      <img
                        src={
                          value.thumbnail &&
                          `${REACT_APP_ENPOINT}/upload/${value.thumbnail}`
                        }
                        alt=""
                        className="img_fixed"
                        style={{
                          width: 114,
                          height: 142,
                          objectFit: "cover",
                        }}
                      />
                    </a>
                  ))}
                </div>
              </div>
              <div className="col-2 prd_view_txt">
                <div className="relative">
                  <p className="prd_view_loca">
                    <span>{t("O'YOUNG Brand")}</span>
                    <i className="arrow right"></i>
                    <span>
                      {data.functions &&
                        (data.functions[locale] || data.functions["en"])}
                    </span>
                  </p>
                  <h2 className="prd_view_title">
                    {data.i18nProductName &&
                      (data.i18nProductName[locale] ||
                        data.i18nProductName["en"])}
                  </h2>
                  {/* <p
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.i18nDescription[locale] ||
                        data.i18nDescription["en"],
                    }}
                  ></p> */}
                  <ul
                    className="hashtag mt-1 mb-2"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.i18nSummary &&
                        (data.i18nSummary[locale] || data.i18nSummary["en"]),
                    }}
                  />
                  <div className="prd_view_btn">
                    <a href="/" className="btn main_qna" onClick={this.onClick}>
                      {t("Contact Seller")}
                    </a>
                    <Link
                      href="/partners/partner"
                      className="btn"
                      locale={locale}
                    >
                      {t("Buy Now")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row prd_mid">
              <h3 className="prd_cont_title">{t("Related Product")}</h3>
              <div className="prd_cont_wr py-3 mt-1 mb-3 owl-carousel prd_related">
                {products.map((value, key) => (
                  <Link
                    href={`/products/products_view/${value.id}`}
                    locale={locale}
                    key={key}
                  >
                    <div className="item mr-1">
                      <img
                        src={
                          value.banners &&
                          value.banners[0] &&
                          `${REACT_APP_ENPOINT}/upload/${value.banners[0].thumbnail}`
                        }
                        alt=""
                        className="img_fixed"
                        style={{
                          width: 232,
                          height: 289,
                        }}
                      />
                      <p>
                        {value.i18nProductName &&
                          ellipsize(value.i18nProductName[locale], 33, {
                            chars: [" ", ","],
                            truncate: true,
                          })}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div className="prd_last">
              <h3 className="prd_cont_title">{t("Product information")}</h3>
              <div className="prd_cont_wr py-3 mt-1 mb-3 prd_last_cont">
                <img
                  src={
                    data.banners &&
                    data.banners[0] &&
                    `${REACT_APP_ENPOINT}/upload/${data.banners[0].path}`
                  }
                  alt=""
                />
                <ul
                  className="hashtag"
                  dangerouslySetInnerHTML={{
                    __html:
                      data.i18nDescription &&
                      (data.i18nDescription[locale] ||
                        data.i18nDescription["en"]),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Popup ref={(ref) => (this.ref = ref)} />
      </div>
    );
  }
}

export default App;
